export const EXAMINATION_PRICE = 30.0;

export const USER_ROLE = {
    PHYSICIAN: 'physician',
    NURSE: 'nurse',
    RECEPTIONIST: 'receptionist',
    ADMINISTRATOR : 'administrator'
}

export const CLIENT_TYPE = {
    GP: 1,
    VIP: 2,
    RANDOM: 3
}

export const PAYMENT_STATUS = {
    PENDING: 1,
    COMPLETED: 2
}

export const GENDER = {
    MALE: 0,
    FEMALE: 1
}

export const MANIPULATION_TYPE = {
    INJECTION: 1,
    PLASMAPHERESIS: 2,
    INFUSION: 3,
    ECHOGRAPHY: 4
}

export const MANIPULATION_TYPE_NAME = {
    1: 'Injection',
    2: 'Plasmapheresis',
    3: 'Infusion',
    4: 'Echography'
}

export const MANIPULATION_TYPE_COLOR = {
    [MANIPULATION_TYPE.INJECTION]: 'neutral-1',
    [MANIPULATION_TYPE.PLASMAPHERESIS]: 'neutral-2',
    [MANIPULATION_TYPE.INFUSION]: 'neutral-3',
    [MANIPULATION_TYPE.ECHOGRAPHY]: 'neutral-4',
}

export const TIME_MASK = [
    {
        length: [1, 2],
        options: [8,9,10,11,12,13,14,15,16,17,18],
        regexp: /^1[0,1-8]$|^0?[1-9]$|^0$/,
        placeholder: 'HH',
    },
    { fixed: ':' },
    {
        length: 2,
        options: ['00', '10', '20', '30', '40', '50'],
        regexp: /^[0-5][0-9]$|^[0-9]$/,
        placeholder: 'mm',
    },
];

export const EMAIL_MASK = [
    {
        regexp: /^[\w\-_.]+$/,
        placeholder: "name"
    },
    {fixed: "@"},
    {
        regexp: /^[\w]+$/,
        placeholder: "startclinic"
    },
    {fixed: "."},
    {
        regexp: /^[\w]+$/,
        placeholder: "com"
    }
];

export const PHONE_NUMBER_MASK = [
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx"
    },
    {fixed: " "},
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx"
    },
    {fixed: " "},
    {
        length: 4,
        regexp: /^[0-9]{1,4}$/,
        placeholder: "xxxx"
    }
];

export const NIN_MASK = [{
    length: 10,
    regexp: /^[0-9]{1,10}$/,
    placeholder: 'xxxxxxxxxx',
}];