import React, { FC, ReactElement } from 'react';
import {gql, useMutation} from "@apollo/client";
import * as Yup from "yup";
import {medicationPackageValidationSchema} from "../Clients/components/ExaminationForm/MedicationPackagePreview";
import {
    FormFieldsWithMedications,
    PrescribeMedicationsInput
} from "../Clients/components/ExaminationForm/PrescribeMedicationsInput";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Form, FormikProvider, useFormik} from "formik";
import {Tile} from "../../components/Tile";
import {Box, Button, FormField} from "grommet";
import {FormStatus} from "../../components/FormStatus";
import {RoutedButton} from "../../components/RoutedButton";
import {
    RestockMedicationAdditionalFields,
    RestockMedicationFields,
    medicationStub
} from "./components/RestockMedicationAdditionalFields";

const CREATE_MUTATION = gql`
    mutation ($data: [medication_package_restock_insert_input!]!) {
        insert_medication_package_restock(objects: $data) {
            affected_rows
        }
    }
`;

const UPDATE_MEDICATION_PRICE = gql`
    mutation ($price: float8! $id: Int!) {
        update_medication_package_by_pk(pk_columns: {id: $id} _set: {price: $price}) {
            id
        }
    }
`;

const validationSchema = Yup.object().shape({
    medications: Yup.array().of(Yup.object().shape({
        price: Yup.number(),
        quantity: Yup.number().required(),
        medication_package_id: Yup.string(),
        medication_package: medicationPackageValidationSchema
    }))
});

const initialValues: FormFieldsWithMedications<RestockMedicationFields> = {
    medications: []
};


export const Restock: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [createRestock] = useMutation(CREATE_MUTATION);
    const [updateMedicationPrice] = useMutation(UPDATE_MEDICATION_PRICE);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                await createRestock({
                    variables: {
                        data: values.medications.map(({medication_package_id, medication_package, sale_price, buy_price, ...rest}) => {
                            if (medication_package_id) {
                                return {...rest, medication_package_id, price: buy_price};
                            }
                            const {__typename, medication, id, dosage_unit, ...medication_package_data} = medication_package;
                            return {
                                ...rest,
                                price: buy_price,
                                medication_package: {
                                    data: {...medication_package_data, price: sale_price}
                                }
                            }
                        })
                    }
                })
                values.medications.forEach(async (value) => {
                    await updateMedicationPrice({variables: {id: value.medication_package_id, price: value.sale_price}});
                })
                navigate('/settings/stock');
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    const {setFieldValue, touched, isSubmitting} = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <Tile loading={isSubmitting}>
                    <FormField label={t('Medications')} contentProps={{pad: {bottom: 'medium'}}}>
                        <PrescribeMedicationsInput
                            // @ts-ignore
                            formik={formik}
                            component={RestockMedicationAdditionalFields}
                            medicationStub={medicationStub}
                            message='No medications selected.'
                        />
                    </FormField>
                    <FormStatus {...formik.status} boxProps={{pad: {vertical: 'small'}}}/>
                </Tile>
                <Box direction='row' gap='small' margin={{top: 'small'}}>
                    <Button primary label={t('Save')} type='submit' onClick={() =>
                        // Remove medication_package_id when medication fields have been touched (i.e create new medication)
                        touched?.medications?.forEach((medication, index) => {
                            medication?.medication_package && setFieldValue(`medications.${index}.medication_package_id`, '')
                        })
                    }/>
                    <RoutedButton path={`/settings/stock`} secondary label={t('Cancel')} color='dark-3'/>
                </Box>
            </Form>
        </FormikProvider>
    );
};