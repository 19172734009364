import React, {FC, ReactElement, useState} from 'react';
import {Box, CheckBox, DateInput, FormField, Text, TextArea} from "grommet";
import {FormikContextType, getIn} from "formik";
import {useTranslation} from "react-i18next";
import {
    FormFieldsWithMedications,
    MedicationPackageFields,
    medicationPackageFieldsStub
} from "./PrescribeMedicationsInput";
import moment from "moment";

export interface MedicationFields extends MedicationPackageFields{
    prescription_note: string,
    start: string,
}

export const medicationStub: MedicationFields = {
    prescription_note: '',
    start: moment().toISOString(),
    ...medicationPackageFieldsStub
}

export interface ClientMedicationFieldsProps {
    formik: FormikContextType<FormFieldsWithMedications<MedicationFields>>,
    index: number,
    modified: boolean
}

export const ClientMedicationFields: FC<ClientMedicationFieldsProps> = ({index, modified, formik}): ReactElement => {
    const {setFieldValue, values, errors, handleChange, handleBlur} = formik;
    const {t} = useTranslation();
    const [useTemplate, setUseTemplate] = useState(true);

    return (
        <Box>
            {/* @ts-ignore */}
            <FormField label={t('Start Date')} error={getIn(errors, `medications.${index}.start`)} width='small'>
                <DateInput
                    name='start'
                    format="dd/mm/yyyy"
                    value={values.medications[index]?.start}
                    onChange={({value}) => setFieldValue(`medications.${index}.start`, value)}
                />
            </FormField>
            <FormField label={
                <Box direction='row' gap='medium'>
                    <Text>{t('Prescription Note')}</Text>
                    <CheckBox
                        checked={useTemplate}
                        label={modified ? t('Set as Template') : t('Use Template')}
                        onChange={(event) => {
                            setUseTemplate(event.target.checked);
                            if (modified) {
                                if (event.target.checked) {
                                    setFieldValue(`medications.${index}.medication_package.prescribing_note`, values.medications[index].prescription_note);
                                } else {
                                    setFieldValue(`medications.${index}.medication_package.prescribing_note`, '');
                                }
                            }
                        }}
                    />
                </Box>}
                       error={getIn(errors, `medications.${index}.prescription_note`)}>
                <TextArea
                    name={`medications.${index}.prescription_note`}
                    value={values.medications[index]?.prescription_note || ''}
                    onChange={(event) => {
                        handleChange(event);
                        modified && useTemplate && setFieldValue(`medications.${index}.medication_package.prescribing_note`, event.target.value);
                    }}
                    onBlur={handleBlur}
                    rows={4}
                />
            </FormField>
        </Box>
    );
};