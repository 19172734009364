import React, { FC, ReactElement } from 'react';
import {ClientForm} from "../components/ClientForm/ClientForm";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {SpinnerContainer} from "../../../components/SpinnerContainer";
import {useTranslation} from "react-i18next";
import {convertNullsToEmptyStrings} from "../../../services/helpers";

const FETCH_CLIENT_INFORMATION = gql`
    query ($id: uuid!) {
        client: client_by_pk(id: $id) {
            id
            first_name
            middle_name
            last_name
            national_identification_number
            primary_physician_id
            user_referral_id
            birth_date
            phone_number
            email
            city_id
            address_line
            postcode
            gender
            type_id
        }
    }
`;

const UPDATE_CLIENT_MUTATION = gql`
    mutation ($id: uuid! $client: client_set_input!) {
        client: update_client_by_pk(pk_columns: {id: $id} _set: $client) {
            id
            first_name
            middle_name
            last_name
            national_identification_number
            primary_physician_id
            user_referral_id
            birth_date
            phone_number
            email
            city_id
            address_line
            postcode
            gender
            type_id
        }
    }
`

export const InformationTab: FC = (): ReactElement => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [update] = useMutation(UPDATE_CLIENT_MUTATION);
    const {data, loading} = useQuery(FETCH_CLIENT_INFORMATION, {variables: {id}});

    if (loading) {
        return <SpinnerContainer/>
    }
    const {id: _, __typename, ...initialValues} = data.client;
    return (
        <ClientForm
            onSubmit={async (client, actions) => {
                try {
                    await update({variables: {id, client}});
                } catch (e) {
                    actions.setStatus({type: 'error', message: t('Something went wrong.')})
                }
            }}
            // @ts-ignore
            initialValues={convertNullsToEmptyStrings(initialValues)}
            backButton={false}
        />
    );
};