import React, {FC, ReactElement} from 'react';
import {ColumnConfig, Text} from "grommet";
import {gql} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {PractitionerCell} from "../components/PractitionerCell";
import {DataTable} from "../../../components/DataTable";
import {useTranslation} from "react-i18next";


const FETCH_MANIPULATIONS = gql`
    query ($limit: Int! $offset: Int! $sort: [client_manipulation_order_by!] $filter: client_manipulation_bool_exp) {
        data: client_manipulation(order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            name
            type_id
            created_at
            manipulation_type {
                id
                name
            }
            description
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
        }
        data_aggregate: client_manipulation_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: number;
    description?: string;
    name: string;
    created_at: string;
    type_id: number;
    manipulation_type: {
        id: number
        name: string
    }
    practitioner?: {
        id: string
        first_name: string
        last_name: string
    }
}

const columns: ColumnConfig<RowType>[] = [
    {
        property: 'created_at',
        header: 'Date',
        render: ({created_at}) => moment(created_at).format('ll')
    },
    {
        property: 'name',
        header: 'Name'
    },
    {
        property: 'type_id',
        header: 'Type',
        render: ({manipulation_type}) => <Text weight='bold'>{manipulation_type.name}</Text>
    },
    // {
    //     property: 'description',
    //     header: 'Description',
    //     render: ({description}) => <Text truncate size='small'>{description}</Text>
    // },
    {
        property: 'practitioner.id',
        header: 'Practitioner',
        render: ({practitioner}) => <PractitionerCell practitioner={practitioner}/>
    },
]


export const ManipulationsTab: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    return (
        <DataTable
            query={FETCH_MANIPULATIONS}
            emptyMessage={t('No manipulations found.')}
            columns={columns}
            onClickRow={(event) => navigate(`${event.datum.id}`)}
            sort={{
                direction: 'desc',
                external: true,
                property: 'created_at'
            }}
            defaultFilter={{client_id: {_eq: id}}}
        />
    );
};