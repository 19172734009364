import React, { FC, ReactElement } from 'react';
import {Box, Text} from "grommet";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {gql, useQuery} from "@apollo/client";
import {PageHeader} from "../../components/PageHeader";
import {Avatar} from "../../components/Avatar";
import {CheckHistoryHours} from "../Profile/CheckHistoryHours";

const FETCH_DATA = gql`
    query ($id: uuid!) {
        user: user_by_pk(id: $id) {
            id
            first_name
            middle_name
            last_name
            gender
            active
            role_id
        }
    }
`;

export const PractitionerPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {id} = useParams();
    const {data: {user} = {user: {}}, loading, error} = useQuery(FETCH_DATA, {variables: {id}});
    return (
        <Box>
            <PageHeader name={t('Practitioner')}/>
            <Box gap='small'>
                <Box direction='row' gap='small' align='center'>
                    <Avatar firstName={user?.first_name} lastName={user?.last_name} size='xxlarge'/>
                    <Text weight='bold'>{`${user.first_name} ${user.last_name} (${t(user.role_id)})`}</Text>
                </Box>
                {/* @ts-ignore */}
                <CheckHistoryHours id={id}/>
            </Box>
        </Box>
    );
};