import React, {FC, ReactElement} from 'react';
import {gql} from "@apollo/client";
import {EnumSelect, EnumSelectProps} from "../../../components/EnumSelect";


const FETCH_MANIPULATION_TYPES = gql`
    query {
        options: manipulation_type {
            id
            name
        }
    }
`;

export const ManipulationTypeSelect: FC<Omit<EnumSelectProps, 'query'>> = (props): ReactElement => (
    <EnumSelect {...props} query={FETCH_MANIPULATION_TYPES} />
);

ManipulationTypeSelect.displayName = 'Select';
