import React, {FC, ReactElement, useState} from 'react';
import {useParams} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";
import {Button, ColumnConfig, Spinner, Text} from "grommet";
import moment from "moment";
import {DataTable} from "../../../components/DataTable";
import {useTranslation} from "react-i18next";
import {Tag} from "../../../components/Tag";
import {PAYMENT_STATUS} from "../../../services/constants";
import {FETCH_CLIENT} from "../components/ClientInformationTile";

export const PaymentStatus: FC<{id: number}> = ({id}): ReactElement => {
    const {t} = useTranslation();
    switch (id) {
        case PAYMENT_STATUS.PENDING:
            return <Tag color='status-warning' label={t('Pending')}/>
        case PAYMENT_STATUS.COMPLETED:
            return <Tag color='status-ok' label={t('Completed')}/>
        default:
            return <Tag color='status-disabled' label={t('N/A')}/>
    }
}

const UPDATE_PAYMENT_STATUS = gql`
    mutation ($ids: [Int!]!) {
        update_client_payment(where: {id: {_in: $ids}} _set: {status_id: ${PAYMENT_STATUS.COMPLETED}}) {
            affected_rows
            returning {
                id
                created_at
                price
                status_id
            }
        }
    }
`

const FETCH_PAYMENTS = gql`
    query ($limit: Int! $offset: Int! $sort: [client_payment_order_by!] $filter: client_payment_bool_exp) {
        data: client_payment(order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            created_at
            price
            status_id
        }
        data_aggregate: client_payment_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: number;
    price: number;
    description?: string;
    created_at: string;
    status_id: number;
}

const columns: ColumnConfig<RowType>[] = [
    {
        property: 'created_at',
        header: 'Date',
        render: ({created_at}) => moment(created_at).format('ll'),
        footer: 'Total',
    },
    {
        property: 'description',
        header: 'Description',
        render: ({description}) => <Text truncate size='small'>{description}</Text>
    },
    {
        property: 'status_id',
        header: 'Status',
        render: ({status_id}) => <PaymentStatus id={status_id}/>
    },
    {
        property: 'price',
        header: 'Price',
        units: 'BGN',
        aggregate: 'sum',
        render: ({price}) => price.toFixed(2),
        footer: { aggregate: true },
    },
]

export const PaymentsTab: FC = (): ReactElement => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [markCompleted, {loading}] = useMutation(UPDATE_PAYMENT_STATUS);
    const [selected, setSelected] = useState<(string |number)[]>([]);
    return (
        <DataTable
            query={FETCH_PAYMENTS}
            emptyMessage={t('No payments found.')}
            columns={columns}
            select={selected}
            onSelect={setSelected}
            // onClickRow={(event) => navigate(`${event.datum.id}`)}
            sort={{
                direction: 'desc',
                external: true,
                property: 'created_at'
            }}
            defaultFilter={{client_id: {_eq: id}}}
        >
            <Button
                primary
                label={t('Mark Completed')}
                color='status-ok'
                disabled={selected.length === 0}
                icon={loading ? <Spinner/> : undefined}
                onClick={async () => {
                    await markCompleted({variables: {ids: selected}, refetchQueries: [FETCH_PAYMENTS, FETCH_CLIENT]});
                    setSelected([]);
                }}
            />
        </DataTable>
    );
};