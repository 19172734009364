import React, {FC, ReactElement, useCallback, useMemo, useState} from 'react';
import {TextInput, TextInputProps} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {getRegExpSearch} from "../../../../services/helpers";
import {FormFieldTheme} from "../../../../components/FormFieldTheme";

const FETCH_PHARMACEUTICAL_FORMS = gql`
    query {
        medication_package (distinct_on: [pharmaceutical_form] order_by: {pharmaceutical_form: asc}) {
            pharmaceutical_form
        }
    }
`

interface Props extends TextInputProps{
}

export const PharmaceuticalFormTextInput: FC<Props> = ({value, onChange, ...rest}): ReactElement => {
    const [suggestions, setSuggestions] = useState([]);
    const {data} = useQuery(FETCH_PHARMACEUTICAL_FORMS, {
        onCompleted: (data: any) =>
            setSuggestions(data.medication_package.map((pack: any) => pack.pharmaceutical_form))
    });
    const handleChange = useCallback((event) => {
        onChange && onChange(event);
        const nextValue = event.target.value;
        if (!nextValue) setSuggestions([]);
        else {
            const exp = getRegExpSearch(nextValue);
            setSuggestions(data.medication_package.map((pack: any) => pack.pharmaceutical_form).filter((s: string) => exp.test(s)))
        }
    }, [onChange, data]);

    const exp = useMemo(() => getRegExpSearch(value), [value]);
    return (
        <FormFieldTheme>
            <TextInput
                {...rest}
                value={value}
                onChange={handleChange}
                suggestions={suggestions}
            />
        </FormFieldTheme>
    );
};