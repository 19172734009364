import React, { FC, ReactElement } from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Box, Select, Spinner, Text} from "grommet";
import {useTranslation} from "react-i18next";
import {SpinnerContainer} from "./SpinnerContainer";

const FETCH_CHECK_OUT_TYPES = gql`
    query {
        options: check_out_type {
            id
            name
        }
    }
`;

const UPDATE_CHECKOUT_TYPE_MUTATION = gql`
    mutation($id: Int! $type_id: Int!) {
        update_user_check_in_or_out_by_pk(pk_columns: {id: $id}, _set: {type_id: $type_id}) {
            id
        }
    }
`;

interface Props  {
    id: number
}

export const CheckOutTypeSelect: FC<Props> = ({id}): ReactElement => {
    const {t} = useTranslation();
    const {data, loading, error} = useQuery(FETCH_CHECK_OUT_TYPES);
    const [updateType, {called, loading: updateLoading}] = useMutation(UPDATE_CHECKOUT_TYPE_MUTATION);

    if (loading) {
        return <SpinnerContainer/>
    }
    console.log('called', called);
    return (
        <Box>
            <Select
                placeholder={t('Select Reason')}
                icon={loading || updateLoading ? <Spinner/> : true}
                labelKey='name'
                valueKey={{key: 'id', reduce: true}}
                options={data?.options || []}
                onChange={async ({value}) => await updateType({variables: {id, type_id: value}})}
            />
            {!updateLoading && called && <Box animation={{type: 'fadeOut', delay: 1000}}>
                <Text color='status-ok' margin={{top: 'xsmall'}}>{t('Saved')}</Text>
            </Box>}
        </Box>
    );
};