import React, { FC, ReactElement } from 'react';
import {DateInput, FormField, MaskedInput, TextInput} from "grommet";
import {NIN_MASK} from "../../../../services/constants";
import {FormikContextType} from "formik";
import {FormFields} from "../../CreateClientPage";
import {useTranslation} from "react-i18next";
import {Section} from "./Section";
import {GenderSelect} from "../GenderSelect";
import moment from "moment";

export const BasicInformationSection: FC<FormikContextType<FormFields>> = ({values,errors,handleChange,handleBlur,setFieldValue}): ReactElement => {
    const {t} = useTranslation();
    return (
        <Section title={t('Basic Information')}>
            <FormField label={t('First Name')} error={errors.first_name}>
                <TextInput
                    name='first_name'
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Middle Name')} error={errors.middle_name}>
                <TextInput
                    name='middle_name'
                    value={values.middle_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Last Name')} error={errors.last_name}>
                <TextInput
                    name='last_name'
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('NIN')} error={errors.national_identification_number}>
                <MaskedInput
                    name='national_identification_number'
                    mask={NIN_MASK}
                    value={values.national_identification_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Birth Date')} error={errors.birth_date}>
                <DateInput
                    name='birth_date'
                    format='dd/mm/yyyy'
                    value={values.birth_date}
                    onKeyUp={(event) => {
                        if (event.key === 'Backspace') {
                            setFieldValue('birth_date', '');
                        }
                    }}
                    onChange={({value}) => {
                        if (value) {
                            setFieldValue('birth_date', moment(value).toISOString(true).split('T')[0])
                        }
                    }}
                />
            </FormField>
            <FormField label={t('Gender')} error={errors.gender}>
                <GenderSelect
                    name='gender'
                    // @ts-ignore
                    value={Number(values.gender)}
                    onChange={({value}) => setFieldValue('gender', !!value)}
                />
            </FormField>
        </Section>
    );
};