import React from 'react';
import {Heading, Main} from "grommet";
import {Logo} from "../components/Logo";
import {RoutedAnchor} from "../components/RoutedAnchor";
import {FatalError} from "../components/FatalError";
import {ErrorBoundary} from "@sentry/react";
import {Route, Routes, Navigate} from "react-router-dom";
import {SignInPage} from "../pages/Auth/SignInPage";
import {SignUpPage} from "../pages/Auth/SignUpPage";
import {RequestPasswordResetPage} from "../pages/Auth/RequestPasswordResetPage";
import {PasswordResetPage} from "../pages/Auth/PasswordResetPage";
import {VerifyProfilePage} from "../pages/Auth/VerifyProfilePage";

export const UnauthorizedLayout = () => (
    <Main background='light-3' justify='center' align='center' pad='large'>
        <RoutedAnchor
            margin={{vertical: 'medium'}}
            icon={<Logo color='light-3'/>}
            color='black'
            // @ts-ignore
            style={{textDecoration: 'none'}}
            label={<Heading level={1} size='small'>Start Clinic</Heading>}
            path='/'
        />
        <ErrorBoundary fallback={(props) => <FatalError {...props} boxProps={{height: {min: 'medium'}}}/>}>
            <Routes>
                <Route path='/' element={<Navigate to='/sign-in' replace/>}/>
                <Route path='/sign-in' element={<SignInPage/>}/>
                <Route path='/sign-up' element={<SignUpPage/>}/>
                <Route path='/verify-profile/:token' element={<VerifyProfilePage/>}/>
                <Route path='/request-password-reset' element={<RequestPasswordResetPage/>}/>
                <Route path='/reset-password/:token' element={<PasswordResetPage/>}/>
                <Route path='*' element={<Navigate to='/sign-in' replace/>}/>
            </Routes>
        </ErrorBoundary>
    </Main>
);