import React, {FC, ReactElement} from 'react';
import {gql} from "@apollo/client";
import {CreateOptionSelect, CreateOptionSelectProps} from "../../../components/CreateOptionSelect";

const CREATE_CITY_MUTATION = gql`
    mutation ($name: String! $country_id: Int!) {
        option: insert_city_one(object: {name: $name, country_id: $country_id}) {
            id
            country_id
            name
        }
    }
`;

interface Props extends Omit<CreateOptionSelectProps, 'query' | 'mutation' | 'labelKey'> {
    country: number | string
}

export const CitySelect: FC<Props> = ({country, ...rest}): ReactElement => {
    return (
        <CreateOptionSelect
            {...rest}
            query={gql`
                query {
                    options: city(where: {country_id: {_eq: ${country}}}) {
                        id
                        name
                        country_id
                    }
                }
            `}
            labelKey='name'
            mutation={CREATE_CITY_MUTATION}
            mutationVariables={{country_id: country}}
        />
    );
};