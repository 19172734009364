import {WebSocketLink} from "@apollo/client/link/ws";
import {AUTH_TOKEN_KEY, loadState} from "../../localStorage";
import {renewToken} from "../auth";
import {SubscriptionClient} from "subscriptions-transport-ws";

const subscriptionClient = new SubscriptionClient((process.env.REACT_APP_GRAPHQL_WS_URL) ?? '', {
    lazy: true,
    reconnect: true,
    connectionCallback: async (error) => {
        // @ts-ignore
        if (error?.includes('JWTExpired')) {
            await renewToken();
            refreshSubscription();
        }
    },
    connectionParams: () => {
        const token = loadState(AUTH_TOKEN_KEY);
        return token ? ({
            headers: {
                Authorization: 'Bearer ' + token
            }
        }) : ({})
    }
});

export const wsLink = new WebSocketLink(subscriptionClient);

export const refreshSubscription = () => {
    subscriptionClient.close(false, false);
};

export const closeSubscription = () => {
    subscriptionClient.close();
};
