import React, { FC, ReactElement } from 'react';
import {SearchSelect, SearchSelectProps} from "../../../../components/SearchSelect";
import {gql} from "@apollo/client";

export const getOptionLabel = (option: any) => `${option.registered_name} (${option.medication.international_nonproprietary_name}) ${option.dosage} ${option.dosage_unit?.unit || ''}`;

const SEARCH_MEDICATIONS = gql`
    query ($query: String! $limit: Int! $offset: Int!) {
        options: medication_package(where: {_or: [
            {medication: {_or: [
                {international_nonproprietary_name: {_ilike: $query}},
                {international_nonproprietary_name_en: {_ilike: $query}}
            ]}}
            {registered_name: {_ilike: $query}},
            {license_holder: {_ilike: $query}}
        ]} limit: $limit offset: $offset order_by: {created_by_user_id: desc, created_at: desc, registered_name: asc}) {
            id
            pharmaceutical_form
            registered_name
            medication_id
            medication {
                id
                international_nonproprietary_name
            }
            price
            dosage
            dosage_unit {
                id
                unit
            }
            dosage_unit_id
        }
    }
`

export const MedicationPackageSelect: FC<Omit<SearchSelectProps, 'query'>> = (props): ReactElement => {
    return (
        <SearchSelect
            {...props}
            labelKey={getOptionLabel}
            query={SEARCH_MEDICATIONS}
        />
    );
};