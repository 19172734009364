import React, {FC, ReactElement, useCallback, useMemo} from "react";
import {Box, BoxProps, Menu, Nav, Sidebar as Sidebox} from "grommet";
import {
    Calendar, Configure, FormAdd, Language,
    UserManager, UserPolice
} from "grommet-icons";
import {matchPath, useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {NavButton} from "./NavButton";
import {USER_ROLE} from "../../../services/constants";
import {useHasAccess} from "../../../services/auth";
import {RoutedButton} from "../../../components/RoutedButton";

export interface Page {
    label: string,
    path: string,
    icon: any,
    active?: boolean,
    exact?: boolean,
    roles: string[],
}
// Don't forget to translate page labels
const pages: Page[] = [
    {
        label: 'Calendar',
        path: '/calendar',
        icon: Calendar,
        roles: [USER_ROLE.ADMINISTRATOR, USER_ROLE.NURSE, USER_ROLE.PHYSICIAN, USER_ROLE.RECEPTIONIST]
    },
    {
        label: 'Clients',
        path: '/clients',
        icon: UserManager,
        roles: [USER_ROLE.ADMINISTRATOR, USER_ROLE.NURSE, USER_ROLE.PHYSICIAN, USER_ROLE.RECEPTIONIST]
    },
    {
        label: 'Practitioners',
        path: '/practitioners',
        icon: UserPolice,
        roles: [USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN]
    }
];

interface SidebarFooterProps {
    hide: () => void
}

const SidebarFooter: FC<SidebarFooterProps> = ({hide}): ReactElement => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const changeLanguage = useCallback((lang: string) => i18n.changeLanguage(lang), [i18n]);

    return (
        <Box alignSelf='start' pad={{vertical: 'small'}}>
            <Menu
                dropAlign={{ top: 'bottom', right: 'right' }}
                icon={false}
                label={<Language color='brand'/>}
                items={[
                    { label: 'EN', onClick: () => changeLanguage('en'), active: i18n.language.includes('en') },
                    { label: 'БГ', onClick: () => changeLanguage('bg'), active: i18n.language.includes('bg') },
                ]}
            />
            <NavButton
                roles={[USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN]}
                label={t('Settings')}
                path='/settings'
                icon={Configure}
                active={matchPath(location.pathname, '/settings') != null}
                onClick={hide}
            />
        </Box>
    );
}

const SidebarHeader: FC = (): ReactElement => {
    const {t} = useTranslation();
    return (
        <Box border={{side: 'bottom', color: 'light-4'}} pad={{bottom: 'small'}}>
            <RoutedButton path='/clients/create' secondary label={t('Client')} icon={<FormAdd size='30px' color='brand'/>} reverse/>
        </Box>
    );
}

const markActiveMenu = (location: Location | any) => pages.map(page =>
    ({
        ...page,
        active: matchPath(location.pathname, page.path) != null
    }))

interface Props extends BoxProps {
    hide: () => void
}

export const Sidebar: FC<Props> = ({hide, ...rest}): ReactElement => {
    const location = useLocation();
    const menus = useMemo<Page[]>(() => (markActiveMenu(location)), [location]);
    const isReceptionist = useHasAccess(USER_ROLE.RECEPTIONIST);
    return (
        <Sidebox
            pad={{horizontal: 'medium', vertical: 'small'}}
            animation={[
                { type: 'fadeIn', duration: 300 },
                { type: 'slideRight', size: 'xlarge', duration: 150 },
            ]}
            header={isReceptionist ? <SidebarHeader/> : undefined}
            footer={<SidebarFooter hide={hide}/>}
            {...rest}
        >
            <Nav gap="medium" align='start'>
                {menus.map((menu) => (
                    <NavButton key={menu.label} {...menu} onClick={hide} />
                ))}
            </Nav>
        </Sidebox>
    );
}