import React, {FC, ReactElement} from 'react';
import {gql} from "@apollo/client";
import {SearchSelect, SearchSelectProps} from "../../../components/SearchSelect";
import {USER_ROLE} from "../../../services/constants";

const SEARCH_PRACTITIONERS = gql`
    query ($query: String! $limit: Int! $offset: Int!) {
        options: user(where: {_or: [{first_name: {_ilike: $query}}, {last_name: {_ilike: $query}}, {middle_name: {_ilike: $query}}]} limit: $limit offset: $offset) {
            id
            first_name
            last_name
        }
    }
`

const SEARCH_PHYSICIANS = gql`
    query ($query: String! $limit: Int! $offset: Int!) {
        options: user(where: {_and: [{role_id: {_eq: ${USER_ROLE.PHYSICIAN}}}{_or: [{first_name: {_ilike: $query}}, {last_name: {_ilike: $query}}, {middle_name: {_ilike: $query}}]}]} limit: $limit offset: $offset) {
            id
            first_name
            last_name
        }
    }
`

interface Props extends Omit<SearchSelectProps, 'query'> {
    physiciansOnly?: boolean
}

export const PractitionerSearchSelect: FC<Props> = ({physiciansOnly = false, ...rest}): ReactElement => {
    return (
        <SearchSelect
            {...rest}
            labelKey={(option) => `${option.first_name} ${option.last_name}`}
            query={physiciansOnly ? SEARCH_PHYSICIANS : SEARCH_PRACTITIONERS}
        />
    );
};