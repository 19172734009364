import React, {FC, ReactElement, useRef} from 'react';
import {Box, Button, Heading, Paragraph, Text} from "grommet";
import {useTranslation} from "react-i18next";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import moment from "moment";
import {Tile} from "../../components/Tile";
import {RoutedButton} from "../../components/RoutedButton";
import {PractitionerCell} from "./components/PractitionerCell";
import {DeleteWithConfirmationButton} from "../../components/DeleteWithConfirmationButton";
import {PaymentStatus} from "./tabs/PaymentsTab";
import {useReactToPrint} from "react-to-print";
import {Prescription} from "./components/Prescription";

const DELETE_MUTATION = gql`
    mutation ($examination_id: Int!) {
        delete_client_examination_by_pk(id: $examination_id) {
            id
        }
    }
`;

const FETCH_QUERY = gql`
    query ($examination_id: Int!) {
        examination: client_examination_by_pk(id: $examination_id) {
            id
            routine
            description
            start_date: start
            diagnoses: client_examination_diagnoses {
                diagnosis {
                    id
                    name
                }
            }
            medications: client_medications {
                id
                prescription_note
                start
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    prescribing_note
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
            physician_id
            physician {
                id
                first_name
                last_name
            }
            client_payment {
                id
                price
                status_id
                description
            }
            client {
                id
                first_name
                middle_name
                last_name
                birth_date
                address_line
                city {
                    id
                    name
                }
            }
        }
    }
`

export const ViewExamination: FC = (): ReactElement => {
    const {id, examination_id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const printRef = useRef<HTMLDivElement>(null);
    const {data: {examination} = {examination: {}}, loading, error} = useQuery(FETCH_QUERY, {variables: {examination_id}});
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `${t('Prescription')} ${moment(examination.start_date).format('L')} ${examination.client?.first_name} ${examination.client?.middle_name} ${examination.client?.last_name}`
    });
    const [deleteExamination] = useMutation(DELETE_MUTATION);
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box gap='small'>
            <Tile loading={loading} boxProps={{gap: 'medium'}}>
                <Box>
                    <Heading level={2} size='small' margin={{vertical: 'small'}}>{`${examination.routine ? t('Routine') : ''} ${t('Examination')} ${t('on')} ${moment(examination.start).format('ll')}`}</Heading>
                    <Paragraph margin={{left: 'small'}}>{examination.description || t('No description.')}</Paragraph>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Diagnoses')}</Text>
                    <Box margin={{left: 'small'}}>{examination.diagnoses.map(({diagnosis}:any) => <Text key={diagnosis.id}>{diagnosis.name}</Text>)}</Box>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Medications')}</Text>
                    <Box margin={{left: 'small'}}>
                        {examination.medications.length > 0 ? examination.medications.map(({id, medication_package, prescription_note, start}: any) =>
                        <Box key={id} gap='xsmall'>
                            <Text>
                                {`${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                            </Text>
                            <Text margin={{left: 'small'}}>{t('Start on')} {moment(start).format('ll')}</Text>
                            <Paragraph margin={{left: 'small'}}>{prescription_note}</Paragraph>
                        </Box>
                        ) : <Text>N/A</Text>}
                    </Box>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Payment')}</Text>
                    {examination.client_payment?.id ?
                        <Box direction='row' gap='small' margin={{left: 'small'}} align='center'>
                            <Text>{examination.client_payment.price.toFixed(2)} {t('BGN')}</Text>
                            <PaymentStatus id={examination.client_payment.status_id}/>
                        </Box>:
                        <Text margin={{left: 'small'}}>{t('No payment required')}</Text>
                    }
                </Box>
                <Box gap='small' direction='row' align='center'>
                    <Text>{t('Examined by')}</Text><PractitionerCell practitioner={examination.physician} size='medium'/>
                </Box>
            </Tile>
            <Box direction='row' gap='small'>
                <DeleteWithConfirmationButton onClick={async () => {
                    try {
                        await deleteExamination({variables: {examination_id}});
                        navigate(`/clients/${id}/examinations`);
                    } catch (e) {
                        console.log('Error', e);
                    }
                }}/>
                <RoutedButton alignSelf='start' path={`/clients/${id}/examinations`} secondary label={t('Back')} color='dark-3' />
                <Button primary color='status-ok' label={t('Print Prescription')} onClick={handlePrint}/>
            </Box>
            {/* @ts-ignore */}
            <div style={{ display: "none" }}><Prescription ref={printRef} data={examination}/></div>
        </Box>

    );
};