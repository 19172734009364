import React, {FC, ReactElement, useState} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {RoutedButton} from "../../components/RoutedButton";
import {Box, CheckBox} from "grommet";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {PractitionerCell} from "./components/PractitionerCell";
import {DataColumnConfig, DataTable} from "../../components/DataTable";
import {ClientTypeTag} from "./components/ClientTypeTag";

const FETCH_CLIENTS = gql`
    query ($limit: Int! $offset: Int! $sort: [client_order_by!] $filter: client_bool_exp) {
        data: client (order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            first_name
            middle_name
            last_name
            email
            phone_number
            national_identification_number
            birth_date
            primary_physician_id
            primary_physician {
                id
                first_name
                last_name
            }
            type_id
        }
        data_aggregate: client_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    national_identification_number: string;
    birth_date: string;
    primary_physician_id?: string;
    primary_physician?: {
        id: string;
        first_name: string;
        last_name: string;
    };
    type_id?: number;
}

const columns: DataColumnConfig<RowType>[] = [
    {
        property: 'first_name',
        header: 'Name',
        search: true,
        searchExpression: (term => ({
            _or: [
                {first_name: {_ilike: `%${term}%`}},
                {middle_name: {_ilike: `%${term}%`}},
                {last_name: {_ilike: `%${term}%`}}
            ]
        })),
        render: ({first_name, last_name}) => `${first_name} ${last_name}`
    },
    {
        property: 'national_identification_number',
        search: true,
        header: 'NIN',
    },
    {
        property: 'phone_number',
        header: 'Phone Number',
    },
    {
        property: 'birth_date',
        header: 'Birthday',
        render: (datum) =>
            datum.birth_date && moment(datum.birth_date).format('Do MMM'),
    },
    {
        property: 'type_id',
        header: 'Type',
        render: (datum) => datum.type_id && <ClientTypeTag id={datum.type_id}/>
    },
    // TODO: Add last visit
    {
        property: 'primary_physician_id',
        header: 'Primary Physician',
        render: ({primary_physician}) => <PractitionerCell practitioner={primary_physician}/>,
    },
];

export const ClientsPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [filter, setFilter] = useState<any>(undefined);
    return (
        <Box>
            <PageHeader name={t('Clients')} action={<RoutedButton secondary label={t('Create Client')} path='/clients/create'/>}/>
            <DataTable
                query={FETCH_CLIENTS}
                emptyMessage={t('No clients found.')}
                onClickRow={(event) => navigate(`/clients/${event.datum.id}`)}
                columns={columns}
                defaultFilter={filter}
                sort={{
                    direction: 'asc',
                    external: true,
                    property: 'first_name'
                }}
            ><CheckBox checked={!!filter} label={t('Have birthday today')} onChange={({target}) => setFilter(target.checked ? {birth_date: {_eq: moment().format(moment.HTML5_FMT.DATE)}} : undefined)}/></DataTable>
        </Box>
    );
};