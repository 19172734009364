import React, { FC, ReactElement } from 'react';
import {useTranslation} from "react-i18next";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {Tile} from "../../components/Tile";
import {Box, Button, FormField, TextInput} from "grommet";
import {GenderSelect} from "../Clients/components/GenderSelect";
import {RoutedButton} from "../../components/RoutedButton";
import {gql, useQuery} from "@apollo/client";
import {convertNullsToEmptyStrings} from "../../services/helpers";
import {useAuth} from "../../services/auth";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    gender: Yup.boolean().nullable(),
    password: Yup.string()
        .min(6, 'Too Short!')
        .max(32, 'Too Long!'),
    password_repeat: Yup.string()
        .when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password'), null], "Passwords must match")
                .required('Required'),
            otherwise: Yup.string()
        })
});


export interface FormFields {
    first_name: string,
    middle_name: string,
    last_name: string,
    email: string,
    gender: boolean | null,
    password: string,
    password_repeat: string,
}

const initialValues: FormFields = {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    gender: null,
    password: '',
    password_repeat: ''
};

const FETCH_DATA = gql`
    query ($user_id: uuid!) {
        userId @client @export(as: "user_id")
        user: user_by_pk(id: $user_id) {
            id
            first_name
            middle_name
            last_name
            gender
            email
        }
    }
`;

export const ProfileForm: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {data: {user: {id, ...userInitial}} = {user: {}}, refetch, loading} = useQuery(FETCH_DATA);
    const {updateProfile} = useAuth();
    const formik = useFormik({
        initialValues: {
            ...initialValues,
            ...convertNullsToEmptyStrings(userInitial)
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            await updateProfile(values, actions);
            await refetch();
        }
    });
    const {values, errors, handleChange, handleBlur, setFieldValue, touched, isSubmitting} = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <Tile loading={loading || isSubmitting}>
                    <Box direction='row-responsive' gap='small'>
                        <FormField label={t('First Name')} error={errors.first_name}>
                            <TextInput
                                name='first_name'
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label={t('Middle Name')} error={errors.middle_name}>
                            <TextInput
                                name='middle_name'
                                value={values.middle_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label={t('Last Name')} error={errors.last_name}>
                            <TextInput
                                name='last_name'
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row-responsive' gap='small'>
                        <FormField label={t('Email')} error={errors.email}>
                            <TextInput
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormField label={t('Gender')} error={errors.gender}>
                            <GenderSelect
                                name='gender'
                                // @ts-ignore
                                value={Number(values.gender)}
                                onChange={({value}) => setFieldValue('gender', !!value)}
                            />
                        </FormField>
                    </Box>
                    <Box direction='row-responsive' gap='small'>
                        <FormField label={t('Password')} error={errors.password}>
                            <TextInput
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type='password'
                                autoComplete="new-password"
                            />
                        </FormField>
                        <FormField label={t('Repeat Password')} error={errors.password_repeat}>
                            <TextInput
                                name='password_repeat'
                                value={values.password_repeat}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type='password'
                                autoComplete="new-password"
                            />
                        </FormField>
                    </Box>
                </Tile>
                <Box direction='row' gap='small' margin={{top: 'small'}}>
                    <Button primary label={t('Save')} type='submit'/>
                    <RoutedButton path='/calendar' secondary label={t('Back')} color='dark-3' />
                </Box>
            </Form>
        </FormikProvider>
    );
};