import React, {FC, ReactElement, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {Calendar, momentLocalizer, SlotInfo, View, Views} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Box, Button, CheckBoxGroup, ResponsiveContext, Text} from "grommet";
import {AppointmentModal} from "./components/AppointmentModal";
import {PageHeader} from "../../components/PageHeader";
import {gql, useQuery, useSubscription} from "@apollo/client";
import {useTranslation} from "react-i18next";
import theme from "../../config/theme";
import {Tile} from "../../components/Tile";
import {useNavigate} from "react-router-dom";
import {Next, Previous} from "grommet-icons";

const TodayButton: FC<{date: Date, onClick: () => void}> = ({date, onClick }): ReactElement => {
    const {t} = useTranslation();
    return (
        <Button primary={moment().isSame(date, 'day')}  label={t('Today')} onClick={onClick}/>
    )
}

const localizer = momentLocalizer(moment);

const FETCH_PRACTITIONERS = gql`
    query {
        practitioners: user (where: {active: {_eq: true}}) {
            id
            first_name
            last_name
        }
    }
`;

const FETCH_APPOINTMENTS = gql`
    subscription ($practitioners: [uuid!]! $start_date: timestamptz! $end_date: timestamptz!) {
        appointments: appointment(where: {_and: [
            {practitioner_id: {_in: $practitioners}},
            {start: {_gte: $start_date}},
            {start: {_lte: $end_date}}
        ]}) {
            id
            start
            practitioner_id
            end
            client_id
            client {
                id
                first_name
                middle_name
                last_name
            }
            description
            data
        }
    }
`;

// TODO: Needs refactoring
export const CalendarPage: FC = (): ReactElement => {
    const {t, i18n} = useTranslation();
    const size = useContext(ResponsiveContext);
    const isSmall = useMemo(() => size === 'small', [size]);
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState<View>(Views.DAY);
    const [appointment, setAppointment] = useState<SlotInfo | any | null>(null);
    const [selectedPractitioners, setSelectedPractitioners] = useState<string[]>([]);
    const [practitioners, setPractitioners] = useState<any[]>([]);
    const {loading, data} = useSubscription(FETCH_APPOINTMENTS, {
        variables: {
            practitioners: selectedPractitioners,
            start_date: (view === Views.DAY ? moment(date) : moment(date).subtract(7, 'days')).set({hours: 0, minutes: 0, seconds: 0}).toISOString(),
            end_date: moment(date).set({hours: 23, minutes: 59, seconds: 59}).toISOString()
        },
        skip: selectedPractitioners.length === 0
    });
    useQuery(FETCH_PRACTITIONERS, {onCompleted: (data: any) => {
            setPractitioners(data.practitioners.map((practitioner: any) =>
                ({...practitioner, name: `${practitioner.first_name} ${practitioner.last_name}`})
            ))
            setSelectedPractitioners(data.practitioners.map(({id}: any) => id));
        }});

    const boxRef = useRef<HTMLDivElement>(null);
    const handleSlotSelect = useCallback(({start, end, resourceId}) => {
        setAppointment({initialValues: {
                start_date: moment(start).toISOString(),
                start_time: moment(start).format('HH:mm'),
                practitioner_id: resourceId,
                duration: Math.round(moment.duration(moment(end).diff(start)).asMinutes()) || 5
            }});
    }, []);

    return (
        <Box fill overflow='auto' ref={boxRef}>
            <PageHeader
                name={t('Calendar')}
                action={<Button secondary label={t('Create') + ' ' + t('Appointment')} onClick={() => setAppointment({})}/>}
            />
            {appointment && <AppointmentModal {...appointment} target={boxRef} onClose={() => setAppointment(null)}/>}
                <Box direction='row-responsive' gap='small' align='center' flex={false}>
                    <Text weight='bold' margin={{right: 'small'}}>{t('Practitioners')}</Text>
                    <CheckBoxGroup
                    wrap
                    pad={{vertical: 'small'}}
                    options={practitioners}
                    value={selectedPractitioners}
                    direction='row'
                    labelKey='name'
                    valueKey='id'
                    // @ts-ignore
                    onChange={({value, option}) => setSelectedPractitioners(value)}
                    />
                </Box>
            <Box direction='row-responsive' justify='between' gap='medium' margin={{top: 'small'}} flex={false}
                 align='center'>
                {!isSmall && <TodayButton date={date} onClick={() => setDate(new Date())}/>}
                <Box direction='row' gap='small' align='center'>
                    <Button secondary icon={<Previous/>}
                            onClick={() =>
                                setDate(date => moment(date).subtract(view === Views.DAY ? 1 : 7, 'days').toDate())
                            }
                    />
                    <Text weight='bold' size='xlarge'>{moment(date).format('ddd, Do MMM')}</Text>
                    <Button secondary icon={<Next/>}
                            onClick={() => setDate(date => moment(date).add(view === Views.DAY ? 1 : 7, 'days').toDate())
                            }
                    />
                </Box>
                <Box direction='row' gap='small' align='center' justify={isSmall ? 'between' : 'stretch'}>
                    {isSmall && <TodayButton date={date} onClick={() => setDate(new Date())}/>}
                    <Box direction='row' gap='small' align='center'>
                        <Text weight='bold'>{t('View')}</Text>
                        <Button label={t('Day')} onClick={() => setView(Views.DAY)} primary={view === Views.DAY}/>
                        <Button label={<Text truncate>{size === 'small' ? t('WW') : t('Work Week')}</Text>}
                                onClick={() => setView(Views.WORK_WEEK)} primary={view === Views.WORK_WEEK}/>
                    </Box>
                </Box>
            </Box>
            <Tile boxProps={{fill: true}} loading={loading}>
                <Calendar
                    date={date}
                    view={view}
                    onNavigate={((newDate, view, action) => {console.log('new', newDate, view, action)})}
                    onView={(view) => console.log('onview', view)}
                    culture={i18n.language}
                    events={data?.appointments || []}
                    localizer={localizer}
                    views={['day', 'work_week']}
                    step={10}
                    timeslots={6}
                    toolbar={false}
                    selectable
                    min={moment().set({'hour': 8, 'minute': 30}).toDate()}
                    max={moment().set({'hour': 18, 'minute': 0}).toDate()}
                    resources={practitioners.filter(((practitioner) => selectedPractitioners.includes(practitioner.id)))}
                    titleAccessor={({client}: any) => `${client.first_name} ${client.last_name}`}
                    startAccessor={(event) => new Date(event.start)}
                    endAccessor={(event) => new Date(event.end)}
                    resourceAccessor='practitioner_id'
                    resourceIdAccessor="id"
                    resourceTitleAccessor="name"
                    tooltipAccessor='description'
                    onSelectSlot={handleSlotSelect}
                    onSelectEvent={({id, start, end, ...event}: any) => {
                        setAppointment({
                            initialValues: {
                                ...event,
                                start_date: moment(start).toISOString(),
                                start_time: moment(start).format('HH:mm'),
                                duration: Math.round(moment.duration(moment(end).diff(start)).asMinutes()) || 5
                            },
                            id
                        })
                    }}
                    // TODO: double click needs to be handled in onSelectEvent
                    //  https://github.com/jquense/react-big-calendar/issues/1564
                    // onDoubleClickEvent={({data, client_id}: any) => {
                    //     navigate(`/clients/${client_id}/${data.type}s/create`);
                    // }}
                    // @ts-ignore
                    eventPropGetter={(event: any) => ({style: {
                            background: event.data?.type === 'examination' ?
                                theme.global?.colors?.['accent-4'] :
                                theme.global?.colors?.['neutral-' + event.data?.manipulation?.type_id]
                        }})}
                    // @ts-ignore
                    // components={{day: EventWrapper}}
                />
            </Tile>
            <Box direction='row' gap='small' wrap alignSelf='end' pad={{horizontal: 'small', top: 'small', bottom: 'xsmall'}}>
                <Text weight='bold' margin={{right: 'small'}}>{t('Legend')}</Text>
                {['Examination', 'Injection', 'Plasmapheresis', 'Infusion', 'Echography'].map((action:string, index) =>
                    <Box direction='row' gap='xsmall' key={index}>
                        <Box width='20px' height='20px' round='full' background={index ? `neutral-${index}` : 'accent-4'}/>
                        <Text>{t(action)}</Text>
                    </Box>
                )}
            </Box>
        </Box>
    );
};