import React, { FC, ReactElement } from 'react';
import {SearchSelect, SearchSelectProps} from "../../../components/SearchSelect";
import {gql} from "@apollo/client";

const SEARCH_COCKTAILS = gql`
    query ($query: String!, $limit: Int!, $offset: Int!) {
        options: cocktail(
            where: {
                _or: [
                    { name: { _ilike: $query } }
                    { description: { _ilike: $query } }
                    { cocktail_medication_packages: { medication_package: { registered_name: {_ilike: $query } } } }
                    { cocktail_medication_packages: { medication_package: { medication: {international_nonproprietary_name: {_ilike: $query } } } } }
                    { cocktail_medication_packages: { medication_package: { medication: {international_nonproprietary_name_en: {_ilike: $query } } } } }
                ]
            }
            limit: $limit
            offset: $offset
            order_by: {name: asc}
        ) {
            id
            name
            price
        }
    }

`

export const CocktailsSearchSelect: FC<Omit<SearchSelectProps, 'query'>> = (props): ReactElement => (
    <SearchSelect
        {...props}
        labelKey='name'
        query={SEARCH_COCKTAILS}
    />
);