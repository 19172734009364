import React, {FC, ReactElement, useEffect, useState} from 'react';
import {Box, Text} from "grommet";
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {Checkmark, Close} from "grommet-icons";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {useAuth} from "../../services/auth";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {}

export const VerifyProfilePage: FC<Props> = (): ReactElement => {
    const {token} = useParams();
    const { t } = useTranslation();
    const [state, setState] = useState<boolean | undefined>(undefined);
    const {verifyProfile} = useAuth();
    useEffect(() => {
        (async () => {
            if (token) {
                setState(await verifyProfile(token));
            } else {
                setState(false);
            }
        })();
    }, [token, verifyProfile]);

    if (state === undefined) {
        return <SpinnerContainer/>
    }

    return (
        <AuthPageWrapper>
            <Box pad='medium' gap='small' align='center'>
                {state ? <Box direction='row' gap='small'>
                    <Checkmark color='status-ok' size='large'/>
                    <Text alignSelf='center'>{t('account_verified')}</Text>
                </Box> : <Box direction='row' gap='small'>
                    <Close color='status-error' size='large'/>
                    <Text alignSelf='center'>{t('expired_link')}</Text>
                </Box>}
                <RoutedAnchor path='/sign-in' label={t('Sign in')}/>
            </Box>
        </AuthPageWrapper>
    );
};