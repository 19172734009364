import React, {FC, ReactElement} from 'react';
import {Box, Text} from "grommet";
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {SignUpForm} from "./components/SignUpForm";
import {SocialAccountsSignIn} from "./components/SocialAccountsSignIn";
import {useTranslation} from "react-i18next";

export const SignUpPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <AuthPageWrapper>
            <Box pad='medium' gap='small'>
                <Text alignSelf='center'>{t('Create your account')}</Text>
                <SignUpForm/>
                {/*<Text alignSelf='center'>Or sign in with your social account</Text>*/}
                {/*<SocialAccountsSignIn/>*/}
            </Box>
        </AuthPageWrapper>
    );
};