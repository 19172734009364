import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Text} from "grommet";
import moment from "moment";
import {Dot} from "./Dot";
import {useTranslation} from "react-i18next";

export const getOpacity = (minutes: number) =>
    minutes > 450 ? 'strong' : minutes > 360 ? 'medium' : 'weak';

interface Props {
    checks: any[],
    date: string
}

export const DayDot: FC<Props> = ({checks, date}): ReactElement => {
    const {t} = useTranslation();
    const duration = useMemo(() => {
        let durationInMinutes = 0;
        let mem: string | undefined;
        checks.forEach((check: any) => {
           if (check.in_or_out) {
               mem = check.created_at;
           } else if (mem) {
               durationInMinutes += moment(check.created_at).diff(mem, 'minutes');
               mem = undefined;
           }
        });
        return durationInMinutes;
    }, [checks]);
    return (
        <Box pad='xsmall'>
            <Dot minutes={duration} tip={<Box><Text>{moment(date).format('ll')}</Text><Text>{t('Time')}: {Math.round(duration / 60) + ':' + duration % 60}</Text></Box>}/>
        </Box>
    );
};