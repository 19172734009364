import React, {FC, ReactElement} from 'react';
import {EnumSelect, EnumSelectProps} from "../../../components/EnumSelect";
import {gql} from "@apollo/client";

const FETCH_CLIENT_TYPES = gql`
    query {
        options: client_type {
            id
            name
        }
    }
`;


export const ClientTypeSelect: FC<Omit<EnumSelectProps, 'query'>> = (props): ReactElement => (
    <EnumSelect {...props} query={FETCH_CLIENT_TYPES}/>
);
