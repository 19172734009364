import React, {FC, ReactElement} from 'react';
import {Text} from "grommet";
import {gql} from "@apollo/client";
import {PractitionerCell} from "../Clients/components/PractitionerCell";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {RoutedButton} from "../../components/RoutedButton";
import {DataColumnConfig, DataTable} from "../../components/DataTable";

const FETCH_QUERY = gql`
    query ($limit: Int! $offset: Int! $sort: [cocktail_order_by!] $filter: cocktail_bool_exp) {
        data: cocktail (order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            name
            description
            price
            created_by_practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
            cocktail_medication_packages {
                cocktail_id
                quantity
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
        }
        data_aggregate: cocktail_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: string;
    name: string;
    description: string;
    price?: number;
    created_by_practitioner_id?: number;
    practitioner?: {
        id: string;
        first_name: string;
        last_name: string;
    };
    cocktail_medication_packages: {
        cocktail_id: number;
        quantity: number;
        medication_package_id: number;
        medication_package: {
            id: number;
            medication_id: number;
            medication: {
                id: number
                international_nonproprietary_name: string
            };
            registered_name: string;
            dosage: string;
            dosage_unit_id: number;
            dosage_unit: {
                id: number
                unit: string
            }
        }
    }[]
}

const columns: DataColumnConfig<RowType>[] = [
    {
        property: 'name',
        header: 'Name',
        search: true
    },
    {
        property: 'cocktail_medication_packages',
        header: 'Medications',
        render: ({cocktail_medication_packages}) =>
            cocktail_medication_packages.map(({medication_package_id, medication_package, quantity}) =>
                <Text key={medication_package_id} size='small'>
                    {`${quantity} x ${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                </Text>
            )
    },
    {
        property: 'price',
        header: 'Price',
        render: ({price}) => (price || 0).toFixed(2),
        units: 'BGN'
    },
    {
        property: 'created_by_practitioner_id',
        header: 'Creator',
        render: ({practitioner}) => <PractitionerCell practitioner={practitioner}/>,
    },
];

export const Cocktails: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <DataTable
            query={FETCH_QUERY}
            onClickRow={(event) => navigate(event.datum.id.toString())}
            emptyMessage={t('No cocktails found.')}
            columns={columns}
            sort={{
                direction: 'asc',
                external: true,
                property: 'name'
            }}
        >
            <RoutedButton path='create' label={t('Create')} alignSelf='start'/>
        </DataTable>
    );
};