import React, {FC, ReactElement} from 'react';
import {ThemeContext} from "grommet";


export const FormFieldTheme: FC = ({children}): ReactElement => {
    return (
        <ThemeContext.Extend
            value={{
                global: {
                    control: {
                        border: {
                            width: "0",
                        },
                    },
                },
            }}
        >
            {children}
        </ThemeContext.Extend>
    );
};