import React from "react";
import {createBrowserHistory, BrowserHistory} from "history";
import {Router, BrowserRouterProps} from "react-router-dom";

interface Props extends BrowserRouterProps {
    history: BrowserHistory
}

export function BrowserRouter({
                                  basename,
                                  children,
                                  window,
                                  history: h
                              }: Props) {
    let historyRef = React.useRef<BrowserHistory>();
    if (historyRef.current == null) {
        historyRef.current = h;
    }

    let history = historyRef.current;
    let [state, setState] = React.useState({
        action: history.action,
        location: history.location
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
}