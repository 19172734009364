import React, {FC, ReactElement, useMemo} from 'react';
import {Box, DataChart, Heading, Text} from "grommet";
import {gql, useQuery} from "@apollo/client";
import moment from "moment";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {DayDot} from "./DayDot";
import {useTranslation} from "react-i18next";
import {Dot} from "./Dot";
import {Tile} from "../../components/Tile";

const FETCH_DATA = gql`
    query ($user_id: uuid! $start_date: timestamptz!) {
        checks: user_check_in_or_out(where: {_and: [{user_id: {_eq: $user_id}}, {created_at: {_gte: $start_date}}]} order_by: {created_at: asc}) {
            id
            user_id
            in_or_out
            created_at
        }
    }
`;

interface Props {
    id: string
}

const START_DATE = moment().subtract(3, 'months').startOf('day').toISOString();

export const CheckHistoryHours: FC<Props> = ({id}): ReactElement => {
    const {t} = useTranslation();
    const {data, loading, error} = useQuery(FETCH_DATA, {
        variables: {
            user_id: id,
            start_date: START_DATE
        }
    })

    const days = useMemo(() => {
        const days: any = {};
        const today = moment();
        let day = moment(START_DATE);
        days[day.format('YYYY-MM-DD')] = [];
        while (!today.isSame(day, 'day')) {
            day = day.add(1, 'day');
            days[day.format('YYYY-MM-DD')] = [];
        }
        data?.checks?.forEach((check: any) => {
            const date = check.created_at.split('T')[0];
            days[date].push(check);
        })
        return days;
    }, [data]);
    // const days = useMemo(() => data?.checks.reduce((groups: any, check: any) => {
    //     const date = check.created_at.split('T')[0];
    //     if (!groups[date]) {
    //         groups[date] = []
    //     }
    //     groups[date].push(check);
    //     return groups;
    // }, {}), [data]);

    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box direction='row-responsive' gap='small'>
            <Tile loading={loading}>
                <Heading level={3} margin={{bottom: 'small', top: 'none'}}>{t('Check in and outs for last 3 months')}</Heading>
                <DataChart
                    data={Object.keys(days).filter((day) => days[day].length > 0).map((day) => {
                        const start = days[day][0].created_at;
                        const end = days[day][days[day].length - 1].created_at;
                        return {
                            day,
                            start: moment(start).diff(moment(start).startOf('day'), 'minutes'),
                            end: moment(end).diff(moment(end).startOf('day'), 'minutes')
                        };
                    })}
                    series={[
                        'day',
                        {
                            label: 'Start time',
                            property: 'start',
                            render: (value, datam, dataIndex) => Math.round(value / 60) + ':' + value % 60
                        },
                        {
                            label: 'End time',
                            property: 'end',
                            render: (value, datam, dataIndex) => Math.round(value / 60) + ':' + value % 60
                        }
                    ]}
                    chart={[
                        {property: 'start', type: 'line', opacity: 'medium', thickness: 'xsmall'},
                        {
                            property: 'start',
                            thickness: 'medium',
                            type: 'point',
                            point: 'diamond',
                        },
                        {property: 'end', type: 'line', opacity: 'medium', thickness: 'xsmall'},

                        {
                            property: 'end',
                            thickness: 'medium',
                            type: 'point',
                            point: 'diamond',
                        },
                    ]}
                    axis={{
                        x: {
                            granularity: 'medium',
                            property: 'day'
                        },
                        // y: {
                        //     granularity: 'fine',
                        //     property: 'start'
                        // }
                    }}
                    detail
                    guide={{x: {granularity: 'medium'}, y: {granularity: 'fine'}}}
                />
            </Tile>
            <Tile loading={loading} boxProps={{gap: 'small', width: '540px', flex: false}}>
                <Heading level={3} margin={{vertical: 'none'}}>{t('Time per day for the last 3 months')}</Heading>
                <Box wrap alignContent='start' height='small'>
                    {Object.keys(days).map((day: string, index) => <DayDot date={day} key={index} checks={days[day]}/>)}
                </Box>
                <Box direction='row' gap='small'>
                    <Text weight='bold'>{t('Legend')}</Text>
                    <Dot minutes={300} tip={t('Less than 6 hours')}/>
                    <Dot minutes={370} tip={t('Over 6 hours')}/>
                    <Dot minutes={460} tip={t('Over 8 hours')}/>
                </Box>
            </Tile>
        </Box>
    );
};