import React, {FC, ReactElement, useState} from 'react';
import {Box, Button, CheckBox, FormField, Text, TextArea, TextInput} from "grommet";
import * as Yup from "yup";
import {medicationPackageValidationSchema} from "./ExaminationForm/MedicationPackagePreview";
import {FormFieldsWithMedications, PrescribeMedicationsInput} from "./ExaminationForm/PrescribeMedicationsInput";
import {
    CocktailMedicationAdditionalFields,
    CocktailMedicationFields, medicationStub
} from "../../Settings/components/CocktailMedicationAdditionalFields";
import {Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Tile} from "../../../components/Tile";
import {FormStatus} from "../../../components/FormStatus";
import {RoutedButton} from "../../../components/RoutedButton";
import {useParams} from "react-router-dom";
import {ManipulationTypeSelect} from "../../Calendar/components/ManipulationTypeSelect";
import {CocktailsSearchSelect} from "./CocktailsSearchSelect";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {MANIPULATION_TYPE, MANIPULATION_TYPE_NAME} from "../../../services/constants";
import {SpinnerContainer} from "../../../components/SpinnerContainer";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    type_id: Yup.number(),
    require_payment: Yup.boolean(),
    price: Yup.number(),
    medications: Yup.array().of(Yup.object().shape({
        quantity: Yup.number(),
        medication_package_id: Yup.string(),
        medication_package: medicationPackageValidationSchema
    }))
});


export interface FormFields extends FormFieldsWithMedications<CocktailMedicationFields>{
    name: string,
    type_id: number,
    description: string,
    require_payment: boolean,
    price: number,
    normal_price: number
}

const initialValues: FormFields = {
    name: '',
    type_id: 1,
    description: '',
    require_payment: true,
    price: 0,
    normal_price: 0,
    medications: []
};

const FETCH_DISCOUNT = gql`
    query ($id: uuid!) {
        client: client_by_pk(id: $id) {
            id
            discount_rate
        }
    }
`;

const FETCH_COCKTAIL_MEDICATIONS = gql`
    query ($ids: [Int]!) {
        medications: cocktail_medication_package (where: {cocktail_id: {_in: $ids}}) {
            quantity
            medication_package_id
            medication_package {
                id
                medication_id
                medication {
                    id
                    international_nonproprietary_name
                }
                pharmaceutical_form
                prescribing_note
                registered_name
                dosage
                dosage_unit_id
                dosage_unit {
                    id
                    unit
                }
            }
        }
    }
`;

const MEDICATION_MANIPULATIONS = [MANIPULATION_TYPE.INFUSION, MANIPULATION_TYPE.INJECTION];

interface Props {
    onSubmit: (data: any, actions: FormikHelpers<FormFields>) => void,
    initialValues?: Partial<FormFields>
}

export const ManipulationForm: FC<Props> = ({onSubmit, initialValues: initial}): ReactElement => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [selectedCocktails, setSelectedCocktails] = useState([]);
    const formik = useFormik({
        initialValues: {
            ...initialValues,
            ...initial
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            const {medications, require_payment, price, normal_price, ...rest} = values;
            try {
                let payment = {};
                if (require_payment) {
                    payment = {
                        client_payment: {
                            data: {
                                price,
                                client_id: id,
                            }
                        }
                    }
                }
                const data = {
                    ...rest,
                    client_id: id,
                    client_manipulation_medication_packages: {
                        data: medications.map(({medication_package_id, medication_package, quantity, ...rest}) => {
                            if (medication_package_id) {
                                return {quantity, medication_package_id};
                            }
                            const {medication, dosage_unit, ...medication_package_data} = medication_package;
                            return {
                                quantity,
                                medication_package: {
                                    data: medication_package_data
                                }
                            }
                        })
                    },
                    ...payment
                };
                await onSubmit(data, actions);
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    const {values, errors, handleChange, handleBlur, setFieldValue, touched, isSubmitting} = formik;
    const {data, loading} = useQuery(FETCH_DISCOUNT, {variables: {id}});
    const [fetchMedications, {called}] = useLazyQuery(FETCH_COCKTAIL_MEDICATIONS, {onCompleted: (data) => setFieldValue('medications', data.medications)});

    if (loading) {
        return <SpinnerContainer/>;
    }

    return (
        <FormikProvider value={formik}>
            <Form>
                <Tile loading={isSubmitting}>
                    <FormField label={t('Type')} error={errors.type_id}>
                        <ManipulationTypeSelect
                            name='type_id'
                            // @ts-ignore
                            value={values.type_id || ''}
                            onChange={({value}) => {
                                setFieldValue('type_id', value);
                                // @ts-ignore
                                setFieldValue('name', t(MANIPULATION_TYPE_NAME[value]));
                            }}
                        />
                    </FormField>
                    {MEDICATION_MANIPULATIONS.includes(values.type_id) && <FormField label={t('Cocktail')}>
                        <CocktailsSearchSelect
                            multiple
                            onChange={async ({value, option}) => {
                                // TODO: Needs refactoring
                                setSelectedCocktails(value);
                                if (value.length > selectedCocktails.length) {
                                    if (!values.name) {
                                        await setFieldValue('name', option.name);
                                    } else {
                                        await setFieldValue('name', values.name + ' ' + option.name);
                                    }
                                } else {
                                    await setFieldValue('name', values.name.replace(option.name, ''));
                                }
                                const discountedItemPrice = Math.round((1 - data.client.discount_rate) * option.price);
                                let newPrice, newNormalPrice;
                                if (value.length > selectedCocktails.length) {
                                    newPrice = values.price + discountedItemPrice;
                                    newNormalPrice = values.normal_price + option.price;
                                } else {
                                    newPrice = values.price - discountedItemPrice;
                                    newNormalPrice = values.normal_price - option.price;
                                }
                                await setFieldValue('normal_price', newNormalPrice);
                                console.log('price', (1 - data.client.discount_rate), newPrice);
                                await setFieldValue('price',  newPrice);
                                await fetchMedications({variables: {ids: value}});
                            }}
                        />
                    </FormField>}
                    <FormField label={t('Name')} error={errors.name}>
                        <TextInput
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                    <FormField label={t('Description')} error={errors.description}>
                        <TextArea
                            name='description'
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={5}
                        />
                    </FormField>
                    {MEDICATION_MANIPULATIONS.includes(values.type_id) && called && <FormField label={t('Medications')} contentProps={{pad: {bottom: 'medium'}}}>
                        <PrescribeMedicationsInput
                            // @ts-ignore
                            formik={formik}
                            component={CocktailMedicationAdditionalFields}
                            medicationStub={medicationStub}
                        />
                    </FormField>}
                    <Box direction='row-responsive' gap='medium'>
                        <CheckBox
                            name='require_payment'
                            checked={values.require_payment}
                            label={t('Requires Payment?')}
                            onChange={(event) => setFieldValue('require_payment', event.target.checked)}
                        />
                        {values.require_payment && <Box direction='row' gap='small'><FormField label={t('Price')} error={errors.price}>
                            <TextInput
                                name='price'
                                value={values.price}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                            {values.normal_price > 0 && <Text alignSelf='center'>{t('Normal Price')}: <strong>{values.normal_price}</strong></Text>}
                        </Box>}
                    </Box>
                    <FormStatus {...formik.status} boxProps={{pad: {vertical: 'small'}}}/>
                </Tile>
                <Box direction='row' gap='small' margin={{top: 'small'}}>
                    <Button primary label={t('Save')} type='submit' onClick={() =>
                        // Remove medication_package_id when medication fields have been touched (i.e create new medication)
                        touched?.medications?.forEach((medication, index) => {
                            medication.medication_package && setFieldValue(`medications.${index}.medication_package_id`, '')
                        })
                    }/>
                    <RoutedButton path={`/clients/${id}/manipulations`} secondary label={t('Cancel')} color='dark-3'/>
                </Box>
            </Form>
        </FormikProvider>
    );
};