import React, {FC, ReactElement} from 'react';
import {Box, Heading, Paragraph, Text} from "grommet";
import {useTranslation} from "react-i18next";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {Tile} from "../../components/Tile";
import {RoutedButton} from "../../components/RoutedButton";
import {DeleteWithConfirmationButton} from "../../components/DeleteWithConfirmationButton";
import {PractitionerCell} from "../Clients/components/PractitionerCell";

const DELETE_MUTATION = gql`
    mutation ($id: Int!) {
        delete_cocktail_by_pk(id: $id) {
            id
        }
    }
`;

const FETCH_QUERY = gql`
    query ($id: Int!) {
        cocktail: cocktail_by_pk(id: $id) {
            id
            name
            description
            price
            medications: cocktail_medication_packages {
                cocktail_id
                quantity
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    prescribing_note
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
            created_by_practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
        }
    }
`

export const ViewCocktail: FC = (): ReactElement => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {data: {cocktail} = {cocktail: {}}, loading, error} = useQuery(FETCH_QUERY, {variables: {id}});
    const [deleteCocktail] = useMutation(DELETE_MUTATION);
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box gap='small'>
            <Tile loading={loading} boxProps={{gap: 'medium'}}>
                <Box>
                    <Heading level={2} size='small' margin={{vertical: 'small'}}>{cocktail.name}</Heading>
                    <Paragraph margin={{left: 'small'}}>{cocktail.description}</Paragraph>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Medications')}</Text>
                    <Box margin={{left: 'small'}}>
                        {cocktail.medications.length > 0 ? cocktail.medications.map(({medication_package_id, medication_package, quantity}: any) =>
                        <Box key={medication_package_id} gap='xsmall'>
                            <Text>
                                {`${quantity} x ${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                            </Text>
                        </Box>
                        ) : <Text>N/A</Text>}
                    </Box>
                </Box>
                <Text><strong>{t('Price')}</strong>: {(cocktail.price || 0).toFixed(2)} {t('BGN')}</Text>
                <Box gap='small' direction='row' align='center'>
                    <Text>{t('Created by')}</Text><PractitionerCell practitioner={cocktail.practitioner} size='medium'/>
                </Box>
            </Tile>
            <Box direction='row' gap='small'>
                <DeleteWithConfirmationButton onClick={async () => {
                    try {
                        await deleteCocktail({variables: {id}});
                        navigate('/settings/cocktails');
                    } catch (e) {
                        console.log('Error', e);
                    }
                }}/>
                <RoutedButton alignSelf='start' path='/settings/cocktails' secondary label={t('Back')} color='dark-3' />
            </Box>
        </Box>

    );
};