import React, { FC, ReactElement } from 'react';
import {FormField, MaskedInput, TextInput} from "grommet";
import {EMAIL_MASK, PHONE_NUMBER_MASK} from "../../../../services/constants";
import {FormikContextType} from "formik";
import {FormFields} from "../../CreateClientPage";
import {useTranslation} from "react-i18next";
import {CountrySelect} from "../CountrySelect";
import {CitySelect} from "../CitySelect";
import {Section} from "./Section";

export const ContactInformationSection: FC<FormikContextType<FormFields>> = ({values,errors,handleChange,handleBlur,setFieldValue}): ReactElement => {
    const {t} = useTranslation();
    return (
        <Section title={t('Contact Information')}>
            <FormField label={t('Phone Number')} error={errors.phone_number}>
                <MaskedInput
                    name='phone_number'
                    mask={PHONE_NUMBER_MASK}
                    value={values.phone_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Email')} error={errors.email}>
                <MaskedInput
                    name='email'
                    mask={EMAIL_MASK}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Address Line')} error={errors.address_line}>
                <TextInput
                    name='address_line'
                    value={values.address_line}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField label={t('Country')} error={errors.country_id}>
                <CountrySelect
                    name='country_id'
                    // @ts-ignore
                    value={values.country_id}
                    onChange={({value}) => setFieldValue('country_id', value)}
                />
            </FormField>
            <FormField label={t('City')} error={errors.city_id}>
                <CitySelect
                    name='city_id'
                    country={values.country_id}
                    value={values.city_id}
                    onChange={({value}) => setFieldValue('city_id', value)}
                />
            </FormField>
            <FormField label={t('Postcode')} error={errors.postcode}>
                <TextInput
                    name='postcode'
                    value={values.postcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
        </Section>
    );
};