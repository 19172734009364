import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextInput, Text, ThemeType, Grommet} from "grommet";
import {Search} from "grommet-icons";
import {deepMerge} from "grommet/utils";
import theme from "../../../config/theme";
import {gql, useQuery} from "@apollo/client";
import {Avatar} from "../../../components/Avatar";
import {useNavigate} from "react-router-dom";

const FETCH_DATA = gql`
    query ($limit: Int! $offset: Int! $filter: client_bool_exp!) {
        clients: client (where: $filter limit: $limit offset: $offset) {
            id
            first_name
            middle_name
            last_name
            national_identification_number
        }
    }
`;

const customTheme: ThemeType = deepMerge(theme, {
    global: {
        drop: {
            shadowSize: 'medium',
            extend: `
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          overflow: hidden;
        `,
        },
        elevation: {
            dark: {
                medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
            light: {
                medium: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
        },
        input: {
            weight: 400,
        },
    },
});

const formatSuggestions = (suggestedFolks: any) =>
    suggestedFolks
        .map(({id, first_name, middle_name, last_name, national_identification_number}: any, index: any, list: any) => ({
            label: (
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    border={index < list.length - 1 ? 'bottom' : undefined}
                    pad="small"
                >
                    <Avatar firstName={first_name} lastName={last_name}/>
                    <Text>
                        <strong>{`${first_name} ${middle_name} ${last_name} (${national_identification_number})`}</strong>
                    </Text>
                </Box>
            ),
            value: id,
        }));

export const JumboSearch = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const filter = useMemo(() => {
        const termFilters = value.split(' ').map((term: string) => ({_or: [
                {first_name: {_ilike: `%${term}%`}},
                {middle_name: {_ilike: `%${term}%`}},
                {last_name: {_ilike: `%${term}%`}},
                {national_identification_number: {_ilike: `%${term}%`}}
            ]}))
        return {_and: termFilters};
    }, [value]);

    useQuery(FETCH_DATA, {variables: {limit: 20, offset: 0, filter}, skip: !value.trim(), onCompleted: data => {
        setSuggestions(formatSuggestions(data.clients));
        }});

    const [suggestionOpen, setSuggestionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const boxRef = useRef();

    const onChange = useCallback((event) => {
        setValue(event.target.value);
    }, []);

    const onSuggestionSelect = useCallback((event) => {
        setSuggestions([]);
        setSuggestionOpen(false);
        setValue('');
        navigate(`/clients/${event.suggestion.value}`);
    }, [navigate]);

    const onSuggestionsOpen = useCallback(() => {
        setSuggestionOpen(true);
    }, []);

    const onSuggestionsClose = useCallback(() => {
        setSuggestions([]);
        setSuggestionOpen(false);
    }, []);

    return (
        <Grommet theme={customTheme}>
            <Box
                // @ts-ignore
                ref={boxRef}

                direction="row"
                align="center"
                pad={{horizontal: 'small', vertical: 'xsmall'}}
                round="small"
                elevation={suggestionOpen ? 'medium' : undefined}
                style={
                    suggestionOpen
                        ? {
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                        }
                        : undefined
                }
            >
                <Search color="brand"/>
                <TextInput
                    dropTarget={boxRef.current}
                    placeholder={t('Search clients...')}
                    plain
                    focusIndicator={false}
                    suggestions={suggestions}
                    value={value}
                    onChange={onChange}
                    onSuggestionsOpen={onSuggestionsOpen}
                    onSuggestionsClose={onSuggestionsClose}
                    onSuggestionSelect={onSuggestionSelect}
                />
            </Box>
        </Grommet>
    );
};