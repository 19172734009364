import React, { FC, ReactElement } from 'react';
import {Box, Button} from "grommet";
import {Tile} from "../../../../components/Tile";
import {BasicInformationSection} from "./BasicInformationSection";
import {ContactInformationSection} from "./ContactInformationSection";
import {InternalInformationSection} from "./InternalInformationSection";
import {FormStatus} from "../../../../components/FormStatus";
import {RoutedButton} from "../../../../components/RoutedButton";
import {FormikHelpers, useFormik} from "formik";
import {nullifyEmptyValues} from "../../../../services/helpers";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Required'),
    national_identification_number: Yup.string().required('Required').min(6).max(10),
    primary_physician_id: Yup.string(),
    type_id: Yup.string(),
    user_referral_id: Yup.string(),
    birth_date: Yup.string(),
    phone_number: Yup.string(),
    email: Yup.string().email(),
    city_id: Yup.string(),
    country_id: Yup.string(),
    address_line: Yup.string(),
    postcode: Yup.string(),
    gender: Yup.boolean().nullable(),
});

export interface FormFields {
    first_name: string,
    middle_name: string,
    last_name: string,
    national_identification_number: string,
    primary_physician_id: string,
    user_referral_id: string,
    birth_date: string,
    phone_number: string,
    email: string,
    city_id: string,
    country_id: string | number,
    address_line: string,
    postcode: string,
    gender: boolean | null,
    type_id: string
}

const initialValues: FormFields = {
    first_name: '',
    middle_name: '',
    last_name: '',
    national_identification_number: '',
    primary_physician_id: '',
    user_referral_id: '',
    birth_date: '',
    phone_number: '',
    email: '',
    city_id: '',
    country_id: 33, // Bulgaria
    address_line: '',
    postcode: '',
    gender: null,
    type_id: ''
};

interface Props {
    onSubmit: (data: any, actions:FormikHelpers<FormFields>) => void
    initialValues?: FormFields,
    backButton?: boolean
}

export const ClientForm: FC<Props> = ({onSubmit, initialValues: initial = {}, backButton = true}): ReactElement => {
    const {t} = useTranslation();
    const formik = useFormik({
        initialValues: {
            ...initialValues,
            ...initial
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                const {country_id, ...client} = values;
                // @ts-ignore
                await onSubmit(nullifyEmptyValues(client));
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Tile boxProps={{pad: 'none', width: {max: 'xxlarge'}}} loading={formik.isSubmitting}>
                <BasicInformationSection {...formik}/>
                <ContactInformationSection {...formik}/>
                <InternalInformationSection {...formik} sectionProps={{border: undefined}}/>
                <FormStatus {...formik.status} boxProps={{pad: {horizontal: 'medium', vertical: 'small'}}}/>
            </Tile>
            <Box direction='row' gap='small' margin={{top: 'small'}}>
                <Button primary label={t('Save')} type='submit'/>
                {backButton && <RoutedButton path='/clients' secondary label={t('Cancel')} color='dark-3' />}
            </Box>
        </form>
    );
};