import React, { FC, ReactElement } from 'react';
import {Text, Avatar as GrommetAvatar, TextProps} from "grommet";

interface Props {
    firstName: string,
    lastName: string,
    size?: TextProps['size']
}

const avatarSizeMap: {[key:string]: string} = {
    'small': 'small',
    'medium': 'medium',
    'large': 'medium',
    'xlarge': 'large',
    'xxlarge': '2xl'
}

export const Avatar: FC<Props> = ({firstName, lastName, size}): ReactElement => {
    return (
        <GrommetAvatar background='light-4' size={size && avatarSizeMap[size]} flex={false}>
            <Text color='dark-1' weight='bold' size={size}>
                {`${firstName?.charAt(0)}${lastName?.charAt(0)}`}
            </Text>
        </GrommetAvatar>
    );
};