import React, { FC, ReactElement } from 'react';
import {Box, Text} from "grommet";

interface Props {
    status: string
}

export const GeneralErrorField: FC<Props> = ({status}): ReactElement | null =>
    status ?
        <Box pad={{vertical: 'small'}}>
            <Text color='status-critical'>{status}</Text>
        </Box> : null;