import React, {FC, ReactElement} from 'react';
import {Box, Button, FormField, TextArea, TextInput} from "grommet";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {Tile} from "../../components/Tile";
import {FormStatus} from "../../components/FormStatus";
import {RoutedButton} from "../../components/RoutedButton";
import {
    FormFieldsWithMedications,
    PrescribeMedicationsInput
} from "../Clients/components/ExaminationForm/PrescribeMedicationsInput";
import {
    CocktailMedicationAdditionalFields,
    CocktailMedicationFields,
    medicationStub
} from "./components/CocktailMedicationAdditionalFields";
import {medicationPackageValidationSchema} from "../Clients/components/ExaminationForm/MedicationPackagePreview";
import {gql, useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";

const CREATE_MUTATION = gql`
    mutation ($data: cocktail_insert_input!) {
        insert_cocktail_one(object: $data) {
            id
            name
            description
            price
        }
    }
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    price: Yup.number(),
    medications: Yup.array().of(Yup.object().shape({
        quantity: Yup.number().required(),
        medication_package_id: Yup.string(),
        medication_package: medicationPackageValidationSchema
    }))
});


export interface FormFields extends FormFieldsWithMedications<CocktailMedicationFields> {
    name: string,
    description: string,
    price: number
}

const initialValues: FormFields = {
    name: '',
    description: '',
    price: 0,
    medications: []
};

export const CreateCocktail: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [createCocktail] = useMutation(CREATE_MUTATION);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                const {medications, name, description, price} = values;
                console.log('med', medications);
                await createCocktail({
                    variables: {
                        data: {
                            name,
                            description,
                            price,
                            cocktail_medication_packages: {
                                data: medications.map(({quantity, medication_package_id, medication_package, ...rest}) => {
                                    if (medication_package_id) {
                                        return {quantity, medication_package_id};
                                    }
                                    const {medication, dosage_unit, ...medication_package_data} = medication_package;
                                    const {__typename, id, ...data} = medication_package_data;
                                    return {
                                        quantity,
                                        medication_package: {
                                            data
                                        }
                                    }
                                })
                            }
                        }
                    }
                })
                navigate('/settings/cocktails');
                console.log('values', values);
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    const {values, errors, handleChange, handleBlur, setFieldValue, touched, isSubmitting} = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <Tile loading={isSubmitting}>
                    <FormField label={t('Name')} error={errors.name}>
                        <TextInput
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                    <FormField label={t('Description')} error={errors.description}>
                        <TextArea
                            name='description'
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={5}
                        />
                    </FormField>
                    <FormField label={t('Price')} error={errors.price}>
                        <TextInput
                            name='price'
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type='number'
                        />
                    </FormField>
                    <FormField label={t('Medications')} contentProps={{pad: {bottom: 'medium'}}}>
                        <PrescribeMedicationsInput
                            // @ts-ignore
                            formik={formik}
                            component={CocktailMedicationAdditionalFields}
                            medicationStub={medicationStub}
                            message='No medications selected.'
                        />
                    </FormField>
                    <FormStatus {...formik.status} boxProps={{pad: {vertical: 'small'}}}/>
                </Tile>
                <Box direction='row' gap='small' margin={{top: 'small'}}>
                    <Button primary label={t('Save')} type='submit' onClick={() =>
                        // Remove medication_package_id when medication fields have been touched (i.e create new medication)
                        touched?.medications?.forEach((medication, index) => {
                            medication?.medication_package && setFieldValue(`medications.${index}.medication_package_id`, '')
                        })
                    }/>
                    <RoutedButton path={`/settings/cocktails`} secondary label={t('Cancel')} color='dark-3'/>
                </Box>
            </Form>
        </FormikProvider>
    );
};