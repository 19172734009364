import {AUTH_TOKEN_KEY, loadState, REFRESH_TOKEN, removeState, saveState} from "../localStorage";
import {FormikHelpers} from "formik";
import API, {AUTH_URL} from "../config/api";
import {useCallback} from "react";
import {useNavigate} from 'react-router-dom';
import {isLoggedInVar} from "./graphql/cache";
import {gql, useQuery} from "@apollo/client";
import {configureScope} from "@sentry/react";
import {closeSubscription} from "./graphql/wsLink";

export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
        userId @client
        userEmail @client
    }
`;

export const renewToken = async () => {
        const result = await API.post(AUTH_URL + 'renew-token', {refresh_token: loadState(REFRESH_TOKEN)});
        saveState(AUTH_TOKEN_KEY, result.data.token);
        return result.data.token;
};

export const manualLogout = () => {
    // Clear Sentry user
    configureScope(scope => scope.setUser(null));
    closeSubscription();
    removeState(AUTH_TOKEN_KEY);
    removeState(REFRESH_TOKEN);
    // Set the logged-in status to false
    isLoggedInVar(false);
};

export const onSubmit = async (values: any, actions: FormikHelpers<any>, url: string): Promise<any | null> => {
    try {
        const result = await API.post(AUTH_URL + url, values);
        return result.data || true;
    } catch (error) {
        // @ts-ignore
        switch (error.response.status) {
            case 422:
                // @ts-ignore
                actions.setErrors(error.response.data.errors);
                break;
            default:
                actions.setStatus({type: 'error', message: 'Something went wrong.'});
        }
        return null;
    }
};

export const useAuth = () => {

    const navigate = useNavigate();

    const login = useCallback(async (values: any, actions: FormikHelpers<any>) => {
        const data = await onSubmit(values, actions, 'login');
        if (!data) {
            return
        }
        saveState(AUTH_TOKEN_KEY, data.token);
        saveState(REFRESH_TOKEN, data.refresh_token);
        isLoggedInVar(true);
    }, []);

    const signUp = useCallback(async (values: any, actions: FormikHelpers<any>) => {
        const data = await onSubmit(values, actions, 'signup')
        if (!data) {
            return
        }
        navigate('/successful-sign-up');
    }, [navigate]);

    const requestPasswordReset = useCallback(async (values: any, actions: FormikHelpers<any>) => {
        const data = await onSubmit(values, actions, 'request-reset-password')
        if (!data) {
            return
        }
        navigate('/sign-in');
    }, [navigate]);

    const resetPassword = useCallback(async (values: any, actions: FormikHelpers<any>) => {
        const data = await onSubmit(values, actions, 'reset-password')
        if (!data) {
            return
        }
        saveState(AUTH_TOKEN_KEY, data.token);
        saveState(REFRESH_TOKEN, data.refresh_token);
        isLoggedInVar(true);
    }, []);

    const logout = useCallback(() => {
        manualLogout();
    }, []);

    const verifyProfile = useCallback(async (token: string) => {
        try {
            await API.post(AUTH_URL + 'verify-profile', {token});
            return true;
        } catch (error) {
            return false;
        }
    }, []);

    const checkInOrOut = useCallback(async (token: string | undefined) => {
        try {
            const result = await API.post(AUTH_URL + 'check', {token});
            return result.data;
        } catch (error) {
            return false;
        }
    }, []);

    const updateProfile = useCallback(async (values: any, actions: FormikHelpers<any>) => {
        const data = await onSubmit(values, actions, 'update-profile');
        if (!data) {
            return
        }
        saveState(AUTH_TOKEN_KEY, data.token);
    }, []);

    return {login, logout, signUp, updateProfile, verifyProfile, requestPasswordReset, resetPassword, checkInOrOut};
};

const FETCH_USER_ROLE = gql`
    query {
        role: userRole @client
    }
`

export const useRole = () => {
    const {data: {role} = {role: ''}} = useQuery(FETCH_USER_ROLE);
    return role;
}

export const useHasAccess = (roles: string | string[]) => {
    const role = useRole();
    return roles.includes(role);
}