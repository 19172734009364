import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Tip} from "grommet";
import {Clipboard, Compliance} from "grommet-icons";
import {useTranslation} from "react-i18next";
import {gql, useSubscription} from "@apollo/client";
import moment from "moment";
import {SpinnerContainer} from "../../../components/SpinnerContainer";

const FETCH_LAST_CHECKIN = gql`
    subscription ($user_id: uuid!) {
        userId @client @export(as: "user_id")
        check_in: user_check_in_or_out(where: {user_id: {_eq: $user_id}} order_by: {created_at: desc} limit: 1) {
            id
            created_at
            in_or_out
        }
    }
`;

export const CheckInOutStatus: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {data, loading} = useSubscription(FETCH_LAST_CHECKIN);
    const checkIn = useMemo(() => data?.check_in?.[0], [data]);
    const checkedIn = useMemo(() => (checkIn?.created_at ? moment().isSame(checkIn?.created_at, 'day') : false) && checkIn?.in_or_out, [checkIn]);

    if (loading) {
        return <SpinnerContainer size='small' pad={{right: 'medium'}}/>
    }

    return (
        <Box animation={checkedIn ? undefined : 'jiggle'}>
            <Tip content={checkedIn ? `${t("Checked in at")} ${moment(checkIn.created_at).format('HH:mm')} today` : t("You haven't checked in today")}>
                {checkedIn ? <Compliance size='30px' color='status-ok'/> : <Clipboard color='status-warning' size='30px'/>}
            </Tip>
        </Box>
    );
};