import {ErrorResponse} from "@apollo/client/link/error";
import {manualLogout, renewToken} from "../auth";
import {fromPromise} from "@apollo/client";
import {refreshSubscription} from "./wsLink";
import {captureException} from "@sentry/react";

let isRefreshing = false;
let pendingRequests: any = [];

const resolvePendingRequests = () => {
    pendingRequests.map((callback: any) => callback());
    pendingRequests = [];
};

// Taken from https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
export const errorHandler = ({graphQLErrors, networkError, operation, forward}: ErrorResponse) => {
    if (graphQLErrors) {
        for (let error of graphQLErrors) {
            if (error.message.includes('JWTExpired')) {
                let forward$;
                if (!isRefreshing) {
                    isRefreshing = true;
                    forward$ = fromPromise(
                        renewToken()
                            .then((token) => {
                                resolvePendingRequests();
                                refreshSubscription();
                                return token;
                            })
                            .catch(error => {
                                captureException(error);
                                pendingRequests = [];
                                return manualLogout();
                            })
                            .finally(() => {
                                isRefreshing = false;
                            })
                    ).filter(value => Boolean(value));
                } else {
                    // Will only emit once the Promise is resolved
                    forward$ = fromPromise(
                        new Promise((resolve: any) => {
                            pendingRequests.push(() => resolve());
                        })
                    );
                }
                return forward$.flatMap(() => forward(operation));
            } else if (error.message.includes('JWSError')) {
                captureException(error);
                manualLogout();
                return;
            }
            captureException(error);
        }
    }
    if (networkError) {
        if (networkError?.message.includes('JWTExpired')) {
            // TODO: Worst solution ever! Could not figure out how to refresh ws link
            window.location.reload();
        } else if (networkError?.message.includes('JWSError')) {
            captureException(networkError);
            manualLogout();
            return;
        }
        captureException(networkError);
    }
}
