import React, {FC, ReactElement} from 'react';
import {Box, FormField, TextInput} from "grommet";
import {FormikContextType, getIn} from "formik";
import {useTranslation} from "react-i18next";
import {
    FormFieldsWithMedications,
    MedicationPackageFields, medicationPackageFieldsStub
} from "../../Clients/components/ExaminationForm/PrescribeMedicationsInput";

export interface RestockMedicationFields extends MedicationPackageFields {
    sale_price: number
    quantity: number
    buy_price: number
}


export const medicationStub: RestockMedicationFields = {
    quantity: 1,
    sale_price: 0.0,
    buy_price: 0.0,
    ...medicationPackageFieldsStub
}

export interface RestockMedicationFieldsProps {
    formik: FormikContextType<FormFieldsWithMedications<RestockMedicationFields>>,
    index: number,
    modified: boolean
}

export const RestockMedicationAdditionalFields: FC<RestockMedicationFieldsProps> = ({index, modified, formik}): ReactElement => {
    const {values, errors, handleChange, handleBlur} = formik;
    const {t} = useTranslation();
    return (
        <Box direction='row' gap='small'>
            <FormField label={t('Quantity')} error={getIn(errors, `medications.${index}.quantity`)}>
                <TextInput
                    name={`medications.${index}.quantity`}
                    value={values.medications[index].quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='number'
                    min={1}
                    max={50}
                    step={1}
                />
            </FormField>
            <FormField label={t('Sale price for an item')} error={getIn(errors, `medications.${index}.sale_price`)}>
                <TextInput
                    name={`medications.${index}.sale_price`}
                    value={values.medications[index].sale_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='number'
                    min={0}
                    // max={50}
                    // step={1}
                />
            </FormField>
            <FormField label={t('Buy price for an item')} error={getIn(errors, `medications.${index}.buy_price`)}>
                <TextInput
                    name={`medications.${index}.buy_price`}
                    value={values.medications[index].buy_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='number'
                    min={0}
                    // max={50}
                    // step={1}
                />
            </FormField>
        </Box>
    );
};