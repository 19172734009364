import React, {FC, ReactElement, useCallback, useMemo, useState} from 'react';
import {SelectExtendedProps} from "grommet";
import {DocumentNode, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {FormSelect} from "./FormSelect";
import {getRegExpSearch} from "../services/helpers";

export interface CreateOptionSelectProps extends Omit<SelectExtendedProps, 'options'>{
    query: DocumentNode,
    queryVariables?: any
    mutation: DocumentNode,
    mutationVariables?: any,
    labelKey: string,
    onChange: ({option, value}: {option: object, value: string | number}) => void,
    fetchAllAtOnce?: boolean,
    limit?: number,
    defaultSearchQuery?: string // Used for preselected query
}

export const CreateOptionSelect: FC<CreateOptionSelectProps> = ({query, mutation, labelKey, queryVariables, mutationVariables, onChange, fetchAllAtOnce = true, limit = 20, defaultSearchQuery = '',  ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [offset, setOffset] = useState(0);
    const prefix = useMemo(() => t('Create'), [t]);
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState(defaultSearchQuery);
    const {data: {options: defaultOptions} = {options: []}, refetch, loading} = useQuery(query, {variables: fetchAllAtOnce ? queryVariables : ({...queryVariables, offset, limit, query: `%${searchValue}%`}), onCompleted: (data) => {
        if (fetchAllAtOnce || !searchValue) {
            setOptions(data.options);
        } else {
            // @ts-ignore
            setOptions(updateCreateOption(searchValue, data.options));
        }
    }})

    const [createOption] = useMutation(mutation);
    const updateCreateOption = useCallback((text, defaultOptions) => {
        const options = [...defaultOptions];
        const len = defaultOptions.length;
        if (len && defaultOptions[len - 1][labelKey].includes(prefix)) {
            // remove Create option before adding an updated one
            options.pop();
        }
        // Hide create empty option and create option that has already been selected (when defaultSearchQuery is defined)
        if (text && defaultSearchQuery !== text) {
            options.push({id: 0, [labelKey]: `${prefix} '${text}'`});
        }

        return options;
    }, [labelKey, prefix, defaultSearchQuery]);
    return (
        <FormSelect
            valueKey={{key: 'id', reduce: true}}
            labelKey={labelKey}
            options={options}
            onChange={async ({ option, value }) => {
                if (option[labelKey].includes(prefix)) {
                    const {data} = await createOption({variables: {[labelKey]: searchValue, ...mutationVariables}});
                    const {data: refetchData} = await refetch(queryVariables);
                    // options needs to be updated before component rerenders
                    setOptions(refetchData.options);
                    await onChange(({option: data.option, value: data.option.id}));
                } else {
                    await onChange(({option, value}))
                }
            }}
            onClose={() => setOptions(defaultOptions)}
            onSearch={(text: string) => {
                const options = updateCreateOption(text, defaultOptions);
                const exp = getRegExpSearch(text);
                // @ts-ignore
                setOptions(options.filter((o:any) => exp.test(o[labelKey])));
                setSearchValue(text);
                setOffset(0);
            }}
            onMore={fetchAllAtOnce ? () => {setOffset(offset => offset + limit)} : undefined}
            {...rest}
        />
    );
};