import React, {FC, ReactElement, useRef, useState} from 'react';
import {Tile} from "../../../components/Tile";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Button, InfiniteScroll, Text, TextArea, FormField, Paragraph} from "grommet";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Avatar} from "../../../components/Avatar";
import moment from "moment";
import {Trash} from "grommet-icons";
import {DeleteWithConfirmationButton} from "../../../components/DeleteWithConfirmationButton";

const validationSchema = Yup.object().shape({
    note: Yup.string().required('Required'),
});

export interface FormFields {
    note: string,
}

const initialValues: FormFields = {
    note: '',
};


const FETCH_NOTES = gql`
    query ($id: uuid! $offset: Int! $limit: Int!) {
        notes: client_note (where: {client_id: {_eq: $id}} order_by: {created_at: desc} offset: $offset limit: $limit) {
            id
            created_at
            note
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
        }
    }
`;

const CREATE_NOTE = gql`
    mutation ($note: String! $client_id: uuid!) {
        note: insert_client_note_one(object: {note: $note, client_id: $client_id}) {
            id
            created_at
            note
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
        }
    }
`;

const DELETE_NOTE = gql`
    mutation ($id: Int!) {
        note: delete_client_note_by_pk(id: $id) {
            id
            created_at
            note
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
        }
    }
`;

const LIMIT = 20;

export const NotesTab: FC = (): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const {id} = useParams();
    const {t} = useTranslation();
    const [notes, setNotes] = useState<any[]>([]);
    const [offset, setOffset] = useState(0);
    const [createNote] = useMutation(CREATE_NOTE);
    const [deleteNote] = useMutation(DELETE_NOTE);
    const {loading, error} = useQuery(FETCH_NOTES, {
        variables: {id, offset, limit: LIMIT},
        onCompleted: (data) => setNotes(notes => notes.concat(data.notes))
    });
    const {values, handleChange, handleBlur, errors, handleSubmit} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                console.log('values', values);
                const {data} = await createNote({variables: {note: values.note, client_id: id}});
                setNotes(notes => [data.note, ...notes]);
                actions.resetForm();
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    return (
        <Box gap='small' fill>
            <form onSubmit={handleSubmit}>
                    <Tile>
                        <FormField error={errors.note}>
                            <TextArea
                                name='note'
                                placeholder={t('Write a note...')}
                                value={values.note}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                    </Tile>
                        <Button primary label={t('Save')} type='submit' margin={{top: 'small'}} alignSelf='start'/>
            </form>
            <Box background='white' border={{color: 'light-3'}} pad='small' round='xsmall' overflow='auto' fill ref={ref}>
                {notes.length > 0 ? <InfiniteScroll items={notes} onMore={() => setOffset(offset => offset + LIMIT)} step={LIMIT} scrollableAncestor={ref.current}>
                    {({id, practitioner, note, created_at}: any, index: number) => (
                        <Box
                            key={id}
                            flex={false}
                            direction='row'
                            gap='medium'
                            margin={{top: index !== 0 ? 'small' : undefined}}
                            // align='center'
                            // background={`dark-${(item % 3) + 1}`}
                        >
                            <Avatar firstName={practitioner?.first_name} lastName={practitioner?.last_name} size='medium'/>
                            <Box>
                                <Box round='small' border={{color: 'light-3'}} pad={{horizontal: 'small'}}><Paragraph>{note}</Paragraph></Box>
                                <Box direction='row' justify='between' margin={{top: 'xsmall'}} pad={{horizontal: 'xsmall'}} gap='xsmall' align='center'>
                                    <DeleteWithConfirmationButton onClick={async () => {
                                        await deleteNote({variables: {id}});
                                        setNotes(notes => notes.filter((note) => note.id !== id));
                                    }} plain icon={<Trash size='small' color='status-error'/>} primary={false} label={undefined}/>
                                    <Text size='xsmall'>{moment(created_at).format('ll')}</Text>
                                </Box>
                            </Box>

                        </Box>
                    )}
                </InfiniteScroll> : <Text>{t('No notes found.')}</Text>}
            </Box>
        </Box>
    );
};