import React, {FC, ReactElement} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, FormField, Spinner, TextInput} from "grommet";
import {GeneralErrorField} from "./GeneralErrorField";
import {useAuth} from "../../../services/auth";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    name: Yup.string().required('Required'),
    password: Yup.string()
        .min(3, 'Too Short!')
        .max(64, 'Too Long!')
        .required('Required'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required('Password repeat is required'),
});

interface FormFields {
    email: string,
    name: string,
    password: string,
    passwordRepeat: string,
}

const initialValues: FormFields = {
    email: '',
    name: '',
    password: '',
    passwordRepeat: ''
};

export const SignUpForm: FC = (): ReactElement => {
    const { t } = useTranslation();
    const {signUp} = useAuth();
    const {errors, values, handleChange, handleSubmit, status, isSubmitting} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: signUp
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormField label={t('Name')} error={errors.name}>
                <TextInput
                    name="name"
                    type="text"
                    value={values.name || ""}
                    onChange={handleChange}
                />
            </FormField>
            <FormField label="Email" error={errors.email}>
                <TextInput
                    name="email"
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                />
            </FormField>
            <FormField label={t('Password')} error={errors.password}>
                <TextInput
                    name="password"
                    type="password"
                    value={values.password || ""}
                    onChange={handleChange}
                />
            </FormField>
            <FormField label={t('Repeat Password')} error={errors.passwordRepeat}>
                <TextInput
                    name="passwordRepeat"
                    type="password"
                    value={values.passwordRepeat || ""}
                    onChange={handleChange}
                />
            </FormField>
            <GeneralErrorField status={status}/>
            <Button
                type='submit'
                label={t('Sign Up')}
                icon={isSubmitting ? <Spinner/> : undefined}
                primary
                fill='horizontal'
                hoverIndicator
                margin={{top: 'small'}}
                size='large'
            />
        </form>
    );
};