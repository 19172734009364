import React, { FC, ReactElement } from 'react';
import {BoxProps, FormField} from "grommet";
import {FormikContextType} from "formik";
import {FormFields} from "../../CreateClientPage";
import {useTranslation} from "react-i18next";
import {PractitionerSearchSelect} from "../../../Calendar/components/PractitionerSearchSelect";
import {Section} from "./Section";
import {ClientTypeSelect} from "../ClientTypeSelect";

interface Props extends FormikContextType<FormFields> {
    sectionProps?: BoxProps
}

export const InternalInformationSection: FC<Props> = ({values,errors,setFieldValue, sectionProps}): ReactElement => {
    const {t} = useTranslation();
    return (
        <Section {...sectionProps} title={t('Internal Information')}>
            <FormField label={t('Client Type')} error={errors.type_id}>
                <ClientTypeSelect
                    name='type_id'
                    value={values.type_id}
                    onChange={({value}) => setFieldValue('type_id', value)}
                />
            </FormField>
            <FormField label={t('Primary Physician')} error={errors.primary_physician_id}>
                <PractitionerSearchSelect
                    name='primary_physician_id'
                    physiciansOnly
                    value={values.primary_physician_id}
                    onChange={({value}) => {
                        setFieldValue('primary_physician_id', value);
                    }}
                />
            </FormField>
            <FormField label={t('Referred by')} error={errors.user_referral_id}>
                <PractitionerSearchSelect
                    name='user_referral_id'
                    value={values.user_referral_id}
                    onChange={({value}) => {
                        setFieldValue('user_referral_id', value);
                    }}
                />
            </FormField>
        </Section>
    );
};