import React, {FC, ReactElement} from 'react';
import {gql} from "@apollo/client";
import {SearchSelect, SearchSelectProps} from "../../../components/SearchSelect";

const SEARCH_CLIENTS = gql`
    query ($query: String! $limit: Int! $offset: Int!) {
        options: client(where: {_or: [{first_name: {_ilike: $query}}, {last_name: {_ilike: $query}}, {national_identification_number: {_ilike: $query}}, {middle_name: {_ilike: $query}}]} limit: $limit offset: $offset) {
            id
            first_name
            last_name
            national_identification_number
        }
    }
`

export const ClientSearchSelect: FC<Omit<SearchSelectProps, 'query'>> = (props): ReactElement => {
    return (
        <SearchSelect
            {...props}
            labelKey={(option) => `${option.first_name} ${option.last_name}`}
            query={SEARCH_CLIENTS}
        />
    );
};