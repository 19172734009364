import React, { FC, ReactElement } from 'react';
import {Box, FormField, TextInput} from "grommet";
import {useTranslation} from "react-i18next";
import {MedicationSelect} from "./MedicationSelect";
import {PharmaceuticalFormTextInput} from "./PharmaceuticalFormTextInput";
import {DosageUnitSelect} from "./DosageUnitSelect";
import {getIn} from "formik";
import * as Yup from "yup";

export const medicationPackageValidationSchema = Yup.object().shape({
    // medication: Yup.object().shape({
    //     id: Yup.string(),
    //     international_nonproprietary_name: Yup.string()
    // }),
    medication_id: Yup.string().required('Required'),
    pharmaceutical_form: Yup.string().required('Required'),
    prescribing_note: Yup.string().nullable(),
    registered_name: Yup.string().required('Required'),
    dosage: Yup.string().required('Required'),
    dosage_unit_id: Yup.string().required('Required'),
    dosage_unit: Yup.object().shape({
        id: Yup.string(),
        unit: Yup.string(),
    })
})

// TODO: Define props interface
export const MedicationPackagePreview: FC<any> = ({medicationPackage, setFieldValue, index, values, handleChange, handleBlur, errors}): ReactElement => {
    const {t} = useTranslation();

    return (
        <Box direction='row-responsive' gap='small' background='light-5' pad='small'>
            <Box>
                <FormField label={t('Non-proprietary Name')} error={getIn(errors, `medications.${index}.medication_package.medication_id`)}>
                    <MedicationSelect
                        name={`medications.${index}.medication_package.medication_id`}
                        defaultSearchQuery={medicationPackage?.medication?.international_nonproprietary_name}
                        value={values.medications[index].medication_package.medication_id}
                        onChange={({value, option}) => {
                            setFieldValue(`medications.${index}.medication_package.medication_id`, value);
                            setFieldValue(`medications.${index}.medication_package.medication`, option);
                        }}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField label={t('Registered Name')} error={getIn(errors, `medications.${index}.medication_package.registered_name`)}>
                    <TextInput
                        name={`medications.${index}.medication_package.registered_name`}
                        value={values.medications[index].medication_package.registered_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Box>
            <Box>
                <FormField label={t('Pharmaceutical Form')} error={getIn(errors, `medications.${index}.medication_package.pharmaceutical_form`)}>
                    <PharmaceuticalFormTextInput
                        name={`medications.${index}.medication_package.pharmaceutical_form`}
                        value={values.medications[index].medication_package.pharmaceutical_form}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onSuggestionSelect={(event) => setFieldValue(`medications.${index}.medication_package.pharmaceutical_form`, event.suggestion)}
                    />
                </FormField>
                <Box direction='row' gap='small'>
                    <FormField label={t('Dosage')} width='xsmall' error={getIn(errors, `medications.${index}.medication_package.dosage`)}>
                        <TextInput
                            name={`medications.${index}.medication_package.dosage`}
                            value={values.medications[index].medication_package.dosage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                    <FormField label={t('Unit')} width='xsmall' error={getIn(errors, `medications.${index}.medication_package.dosage_unit_id`)}>
                        <DosageUnitSelect
                            name={`medications.${index}.medication_package.dosage_unit_id`}
                            value={values.medications[index].medication_package.dosage_unit_id}
                            onChange={({value, option}) => {
                                setFieldValue(`medications.${index}.medication_package.dosage_unit_id`, value);
                                setFieldValue(`medications.${index}.medication_package.dosage_unit`, option);
                            }}
                            onBlur={handleBlur}
                        />
                    </FormField>
                </Box>
            </Box>
        </Box>
    );
};