import React, { FC, ReactElement } from 'react';
import {Page} from "./Sidebar";
import {useTranslation} from "react-i18next";
import {RoutedButton} from "../../../components/RoutedButton";
import {useHasAccess} from "../../../services/auth";

interface Props extends Page {
    onClick: () => void
}

export const NavButton: FC<Props> = ({onClick, icon: Icon, label, path, active, roles}): ReactElement | null => {
    const { t } = useTranslation();
    const hasAccess = useHasAccess(roles);
    if (hasAccess)
        return (
            <RoutedButton
                path={path}
                icon={<Icon color={active ? 'brand' : 'dark-2'}/>}
                label={t(label)}
                plain
                color={active ? 'brand' : 'dark-1'}
                onClick={onClick}
            />
    );
    return null;
};