import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Tab, Tabs} from "grommet";
import {PageHeader} from "../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

const TABS = ['stock', 'cocktails', 'vip-clients'];

export const SettingsPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = useMemo(() => {
        const loc = location.pathname.split('/');
        return TABS.findIndex((tab) => loc.includes(tab));
    }, [location]);

    return (
        <Box>
            <PageHeader name={t('Settings')}/>
            <Box gap='small'>
                <Tabs flex={false} alignControls='start' onActive={(index) => navigate(TABS[index])} activeIndex={activeTab}>
                    <Tab title={t('Medication Stock')}/>
                    <Tab title={t('Medication Cocktails')}/>
                    <Tab title={`VIP ${t('Clients')}`}/>
                </Tabs>
                <Outlet/>
            </Box>
        </Box>
    );
};