import React, {FC, ReactElement, useEffect, useState} from 'react';
import {Box, Heading, Paragraph} from "grommet";
import {useParams} from "react-router-dom";
import {useAuth} from "../services/auth";
import {Checkmark, Close} from "grommet-icons";
import {useTranslation} from "react-i18next";
import {RoutedButton} from "../components/RoutedButton";
import moment from "moment";
import {CheckOutTypeSelect} from "../components/CheckOutTypeSelect";
import {SpinnerContainer} from "../components/SpinnerContainer";

const DATE_FORMAT = 'HH:mm, dddd D MMM YYYY';

export const CheckPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {token} = useParams();
    const {checkInOrOut} = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [check, setCheck] = useState<any | boolean>({});

    useEffect(() => {
        (async () => {
            setLoading(true);
            const check = await checkInOrOut(token);
            setCheck(check);
            setLoading(false);
        })()
    }, [token, checkInOrOut]);

    if (loading) {
        return <SpinnerContainer/>
    }

    if (check === false) {
        return (
            <Box align='center' fill justify='center'>
                <Box direction='row' gap='small' align='center'>
                    <Close size='large' color='status-error'/><Heading level={2}>{t('Something went wrong.')}</Heading>
                </Box>
                <Paragraph>Please connect to the WiFi network in the clinic and rescan the QR Code.</Paragraph>
            </Box>
        )
    }
    return (
        <Box fill align='center' justify='center'>
            {check.in_or_out ?
                <Box>
                    <Box direction='row' gap='small' align='center'>
                        <Checkmark size='large' color='status-ok'/>
                        <Heading level={2}>{t('Successful check in!')}</Heading>
                    </Box>
                    <Paragraph textAlign='center'>{moment().format(DATE_FORMAT)}</Paragraph>
                    <RoutedButton path='/calendar' label={t('Start using the platform')} primary/>
                </Box> :
                <Box>
                    <Box direction='row' gap='small' align='center'>
                        <Checkmark size='large' color='status-ok'/>
                        <Heading level={2}>{t('Successful check out!')}</Heading>
                    </Box>
                    <Paragraph textAlign='center'>{moment().format(DATE_FORMAT)}</Paragraph>
                    <CheckOutTypeSelect id={check.id}/>
                    <RoutedButton path='/calendar' label={t('Back to the platform')} primary margin={{top: 'medium'}}/>
                </Box>
            }
        </Box>
    );
};