import React, {FC, ReactElement, useContext} from 'react';
import {Box, Text, Header as Head, BoxProps, ResponsiveContext} from "grommet";
import {Logo} from "../../../components/Logo";
import {JumboSearch} from "./JumboSearch";
import {UserSession} from "./UserSession";

interface Props extends BoxProps {}


export const Header: FC<Props> = ({onClick, ...rest}): ReactElement => {
    const size = useContext(ResponsiveContext);
    return (
        <Head border={{side: 'bottom', color: 'light-3'}} background='white' pad={{horizontal: 'small'}} {...rest}>
            <Box direction='row' gap='large' align='center'>
            <Box direction='row' gap='small' onClick={onClick} focusIndicator={false} align='center'>
                <Box round='xxsmall' pad='xxsmall' background='light-3'><Logo/></Box>
                {size !== 'small' && <Text weight='bold' color='dark-1' size='large'>Start Clinic</Text>}
            </Box>
                <JumboSearch/>
            </Box>
            <UserSession/>
        </Head>
    );
};