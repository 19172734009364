import React, {FC, ReactElement} from 'react';
import {Box, Heading, Grid, BoxProps} from "grommet";

interface SectionProps extends BoxProps{
    title: string
}

export const Section: FC<SectionProps> = ({children, title, ...rest}): ReactElement => (
    <Box border={{side: 'bottom', color: 'light-3'}} pad='medium' {...rest}>
        <Heading level={4} margin={{bottom: 'small', top: 'none'}}>{title}</Heading>
        <Grid
            gap='medium'
            columns={{count: 'fit', size: 'small'}}
        >
            {children}
        </Grid>
    </Box>
);
