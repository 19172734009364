import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, Grid, ResponsiveContext} from "grommet";
import {ErrorBoundary} from "@sentry/react";
import {FatalError} from "../../components/FatalError";
import {Header} from "./Header/Header";
import {Sidebar} from "./Sidebar/Sidebar";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {CalendarPage} from "../../pages/Calendar/CalendarPage";
import {PractitionersPage} from "../../pages/Practitioners/PractitionersPage";
import {ClientsPage} from "../../pages/Clients/ClientsPage";
import {CreateClientPage} from "../../pages/Clients/CreateClientPage";
import {ClientPage} from "../../pages/Clients/ClientPage";
import {ExaminationsTab} from "../../pages/Clients/tabs/ExaminationsTab";
import {CreateExamination} from "../../pages/Clients/CreateExamination";
import {CreateManipulation} from "../../pages/Clients/CreateManipulation";
import {EditExamination} from "../../pages/Clients/EditExamination";
import {ViewExamination} from "../../pages/Clients/ViewExamination";
import {SettingsPage} from "../../pages/Settings/SettingsPage";
import {Cocktails} from "../../pages/Settings/Cocktails";
import {CreateCocktail} from "../../pages/Settings/CreateCocktail";
import {ViewCocktail} from "../../pages/Settings/ViewCocktail";
import {ManipulationsTab} from "../../pages/Clients/tabs/ManipulationsTab";
import {ViewManipulation} from "../../pages/Clients/ViewManipulation";
import {NotesTab} from "../../pages/Clients/tabs/NotesTab";
import {InformationTab} from "../../pages/Clients/tabs/InformationTab";
import {Stock} from "../../pages/Settings/Stock";
import {Restock} from "../../pages/Settings/Restock";
import {ProfilePage} from "../../pages/Profile/ProfilePage";
import {CheckPage} from "../../pages/CheckPage";
import {PractitionerPage} from "../../pages/Practitioners/PractitionerPage";
import {PaymentsTab} from "../../pages/Clients/tabs/PaymentsTab";
import {VipClients} from "../../pages/Settings/VipClients";

export const AuthorizedLayout = () => {
    const [sidebar, setSidebar] = useState(true);
    const size = useContext(ResponsiveContext);
    const navigate = useNavigate();

    const hideSidebar = useCallback(() => size === 'small' && setSidebar(false), [size]);

    useEffect(() => {
        if (size !== 'small') {
            setSidebar(true);
        } else {
            setSidebar(false);
        }
    }, [size]);

    return (
        <Box fill background='light-2'>
            <Grid
                fill
                rows={['auto', 'flex']}
                columns={['auto', 'flex']}
                gap="small"
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'sidebar', start: [0, 1], end: [0, 1] },
                    { name: 'main', start: [1, 1], end: [1, 1] },
                ]}
            >
                <Header gridArea='header' onClick={() => size === 'small' ? setSidebar(sidebar => !sidebar) : navigate('/')}/>
                {sidebar && <Sidebar gridArea='sidebar' hide={hideSidebar}/>}
                <Box gridArea="main" as="main" pad={{horizontal: 'small', bottom: 'small'}}>
                    <ErrorBoundary fallback={(props) => <FatalError {...props} boxProps={{flex: false, fill: true}}/>}>
                        <Routes>
                            <Route index element={<Navigate to='/calendar' replace/>}/>
                            <Route path='/sign-in' element={<Navigate to='/calendar' replace/>}/> {/* Hack: redirect to home page on login*/}
                            <Route path='/check/:token' element={<CheckPage/>}/>
                            <Route path='/check' element={<CheckPage/>}/>
                            <Route path='/calendar' element={<CalendarPage/>}/>
                            <Route path='/profile' element={<ProfilePage/>}/>
                            <Route path='/practitioners' element={<PractitionersPage/>}/>
                            <Route path='/practitioners/:id' element={<PractitionerPage/>}/>
                            <Route path='/clients' element={<ClientsPage/>}/>
                            <Route path='/clients/:id' element={<ClientPage/>}>
                                <Route index element={<Navigate to='examinations' replace/>}/>
                                <Route path='examinations' element={<ExaminationsTab/>}/>
                                <Route path='examinations/create' element={<CreateExamination/>}/>
                                <Route path='examinations/:examination_id/edit' element={<EditExamination/>}/>
                                <Route path='examinations/:examination_id' element={<ViewExamination/>}/>
                                <Route path='manipulations' element={<ManipulationsTab/>}/>
                                <Route path='manipulations/create' element={<CreateManipulation/>}/>
                                <Route path='manipulations/create/:type_id' element={<CreateManipulation/>}/>
                                <Route path='manipulations/:manipulation_id' element={<ViewManipulation/>}/>
                                <Route path='payments' element={<PaymentsTab/>}/>
                                <Route path='notes' element={<NotesTab/>}/>
                                <Route path='information' element={<InformationTab/>}/>
                            </Route>
                            <Route path='/clients/create' element={<CreateClientPage/>}/>
                            <Route path='/settings' element={<SettingsPage/>}>
                                <Route index element={<Navigate to='stock' replace/>}/>
                                <Route path='stock' element={<Stock/>}/>
                                <Route path='stock/restock' element={<Restock/>}/>
                                <Route path='cocktails' element={<Cocktails/>}/>
                                <Route path='cocktails/:id' element={<ViewCocktail/>}/>
                                <Route path='cocktails/create' element={<CreateCocktail/>}/>
                                <Route path='vip-clients' element={<VipClients/>}/>
                            </Route>
                        </Routes>
                    </ErrorBoundary>
                </Box>
            </Grid>
        </Box>
    );
}