import {ApolloClient, ApolloLink, NormalizedCacheObject} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {getMainDefinition} from "@apollo/client/utilities";
import {cache, typeDefs} from "../services/graphql/cache";
import {httpLink} from "../services/graphql/httpLink";
import {wsLink} from "../services/graphql/wsLink";
import {errorHandler} from "../services/graphql/errorHandler";


// Use wsLink for subscriptions and httpLink for all other queries
const link = ApolloLink.split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);


export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: onError(errorHandler).concat(link),
    typeDefs,
    resolvers: {},
});