import React, {FC, ReactElement} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, FormField, Spinner, TextInput} from "grommet";
import {useAuth} from "../../../services/auth";
import {GeneralErrorField} from "./GeneralErrorField";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(3, 'Too Short!')
        .max(64, 'Too Long!')
        .required('Required')
});

interface FormFields {
    email: string,
    password: string
}

const initialValues: FormFields = {
    email: '',
    password: ''
};

export const SignInForm: FC = (): ReactElement => {
    const { t } = useTranslation();
    const {login} = useAuth();
    const {errors, values, handleChange, handleSubmit, status, isSubmitting} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: login
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormField label="Email" error={errors.email}>
                <TextInput
                    name="email"
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                />
            </FormField>
            <FormField label={t('Password')} error={errors.password}>
                <TextInput
                    name="password"
                    type="password"
                    value={values.password || ""}
                    onChange={handleChange}
                />
            </FormField>
            <GeneralErrorField status={status}/>
            <Button
                type='submit'
                label={t('Login')}
                icon={isSubmitting ? <Spinner/> : undefined}
                size='large'
                primary
                fill='horizontal'
                margin={{top: 'small'}}
                hoverIndicator
            />
        </form>
    );
};