import {deepMerge} from "grommet/utils";
import {grommet, ThemeType} from "grommet";


const theme: ThemeType = deepMerge(grommet, {
    global: {
        size: {
            header: "64px"
        },
        breakpoints: {
        },
        colors: {
            'accent-4': '#FFCA58',
            'neutral-1': '#00873D',
            'neutral-2': '#3D138D',
            'neutral-3': '#00739D',
            'neutral-4': '#A2423D',
        }
    },
    button: {
        badge: {
            container: {
                background: 'accent-1'
            }
        }
    },
    textInput: {
        disabled: {
            opacity: 0.9
        }
    }
});

export default theme;