import React, {FC, ReactElement, useMemo} from 'react';
import {
    Box,
    Button,
    CheckBox,
    DateInput,
    FormField,
    Heading,
    Layer,
    MaskedInput,
    Select,
    TextArea,
    TextInput,
    Text
} from "grommet";
import {useTranslation} from "react-i18next";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {capitalize} from "../../../services/helpers";
import {ManipulationTypeSelect} from "./ManipulationTypeSelect";
import {ClientSearchSelect} from "./ClientSearchSelect";
import {PractitionerSearchSelect} from "./PractitionerSearchSelect";
import moment from "moment";
import {TIME_MASK, USER_ROLE} from "../../../services/constants";
import {gql, useMutation} from "@apollo/client";
import {FormStatus} from "../../../components/FormStatus";
import {DeleteWithConfirmationButton} from "../../../components/DeleteWithConfirmationButton";
import {RoutedButton} from "../../../components/RoutedButton";
import {useRole} from "../../../services/auth";

interface Props {
    onClose: () => void,
    target: any,
    start?: string,
    end?: string,
    practitioner_id?: string,
    initialValues?: FormFields,
    id?: number // Passing this means that we are updating that appointment
}

const validationSchema = Yup.object().shape({
    practitioner_id: Yup.string()
        .required('Required'),
    client_id: Yup.string()
        .required('Required'),
    description: Yup.string(),
    start_time: Yup.string().required('Required'),
    start_date: Yup.string().required('Required'),
    duration: Yup.number().required('Required'),
});

interface FormFields {
    practitioner_id: string,
    start_date: string,
    start_time: string,
    duration: number,
    client_id: string,
    description: string,
    data: {
        type: 'examination' | 'manipulation',
        examination?: {
            routine: boolean
        },
        manipulation?: {
            type_id: number | string
            cocktail_id?: number | string
        }
    },
}

const defaultInitialValues: FormFields = {
    practitioner_id: '',
    start_date: moment().toISOString(),
    start_time: '',
    duration: 5,
    client_id: '',
    description: '',
    data: {
        type: 'examination',
        examination: {
            routine: false
        }
    }
};

const CREATE_APPOINTMENT_MUTATION = gql`
    mutation ($data: appointment_insert_input!) {
        insert_appointment_one(object: $data) {
            id
            start
            practitioner_id
            end
            client_id
            client {
                id
                first_name
                middle_name
                last_name
            }
            description
            data
        }
    }
`;

const DELETE_APPOINTMENT_MUTATION = gql`
    mutation ($id: Int!) {
        delete_appointment_by_pk(pk_columns: {id: $id}) {
            id
        }
    }
`;

const UPDATE_APPOINTMENT_MUTATION = gql`
    mutation ($id: Int! $data: appointment_set_input!) {
        update_appointment_by_pk(pk_columns: {id: $id} _set: $data) {
            id
            start
            practitioner_id
            end
            client_id
            description
            data
        }
    }
`;


export const AppointmentModal: FC<Props> = ({onClose, target, initialValues = {}, id}): ReactElement => {
    const {t} = useTranslation();
    const [deleteMutation] = useMutation(DELETE_APPOINTMENT_MUTATION); // Only when id is passed
    const [mutation] = useMutation(id ? UPDATE_APPOINTMENT_MUTATION : CREATE_APPOINTMENT_MUTATION);
    const role = useRole();
    const formik = useFormik({
        initialValues: {
            ...defaultInitialValues,
            ...initialValues,
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                const {start_date, start_time, duration, ...rest} = values;
                const time = moment(start_time, 'HH:mm');
                const start = moment(start_date).set({hours: time.get('hours'), minutes: time.get('minutes')});
                const data = {
                    ...rest,
                    start: start.toISOString(),
                    end: start.add(duration, 'minutes').toISOString()
                };
                const variables = id ? {id, data} : {data};
                await mutation({variables});
                onClose();
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    const {errors, values, handleChange, handleBlur, setFieldValue, status} = formik;
    const showCreateButton = useMemo(() => {
        // TODO: Needs refactoring
        if (!id) {
            return false;
        } else if (values.data.type === 'manipulation' && [USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN, USER_ROLE.NURSE].includes(role)) {
            return true;
        } else if (values.data.type === 'examination' && [USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN].includes(role)) {
            return true;
        }
        return false;
    }, [id, role, values]);
    return (
        <Layer onClickOutside={onClose} onEsc={onClose} responsive={false}>
            <FormikProvider value={formik}>
                <Form>
                    <Box pad="medium" gap="small">
                        <Heading level={3} margin="none">
                            {id ? t('Update') : t('Create')} {t('Appointment')}
                        </Heading>
                        <Box direction='row' gap='medium'>
                            <FormField label={t('Date')} error={errors.start_date} width='small'>
                                <DateInput
                                    name='start_date'
                                    format="dd/mm/yyyy"
                                    value={values.start_date}
                                    onChange={({value}) => setFieldValue('start_date', value)}
                                />
                            </FormField>
                            <FormField label={t('Time')} error={errors.start_time} width='xsmall'>
                                <MaskedInput
                                    name='start_time'
                                    mask={TIME_MASK}
                                    value={values.start_time}
                                    onChange={event => {
                                        setFieldValue('start_time', event.target.value)
                                        console.log('e', event.target.value)
                                    }}
                                />
                            </FormField>
                            <FormField label={t('Duration')} error={errors.duration} contentProps={{direction: 'row'}} width='xsmall'>
                                <TextInput
                                    name='duration'
                                    value={values.duration}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type='number'
                                    min={5}
                                    max={360}
                                    step={5}
                                />
                                <Text alignSelf='center'>{t('min')}</Text>
                            </FormField>
                        </Box>
                        <Box direction='row' gap='medium' flex>
                            <FormField label={t('Type')} error={errors.data?.type} width='small'>
                                <Select
                                    name='data.type'
                                    options={['examination', 'manipulation']}
                                    value={values.data.type}
                                    labelKey={(option) => t(capitalize(option))}
                                    onChange={({option}) => {
                                        setFieldValue('data.type', option);
                                        setFieldValue('data.manipulation', undefined);
                                    }}
                                />
                            </FormField>
                            {values.data.type === 'examination' && <FormField error={errors.data?.examination} style={{justifyContent: 'end'}}>
                                <CheckBox
                                    name='data.examination.routine'
                                    value={+(values.data.examination?.routine || 0)}
                                    label={t('Routine')}
                                    onChange={(event) => setFieldValue('data.examination.routine', event.target.checked)}
                                />
                            </FormField>}
                            {values.data.type === 'manipulation' && <FormField label={t('Manipulation Type')} error={errors.data?.type} width='216px'>
                                <ManipulationTypeSelect
                                    name='data.manipulation.type_id'
                                    // @ts-ignore
                                    value={values.data.manipulation?.type_id || ''}
                                    onChange={({value}) => setFieldValue('data.manipulation.type_id', value)}
                                />
                            </FormField>}
                        </Box>
                        <FormField label={t('Client')} error={errors.client_id}>
                            <ClientSearchSelect
                                name='client_id'
                                value={values.client_id}
                                onChange={({value}) => {
                                    setFieldValue('client_id', value);
                                }}
                            />
                        </FormField>
                        <FormField label={t('Practitioner')} error={errors.practitioner_id}>
                            <PractitionerSearchSelect
                                name='practitioner_id'
                                value={values.practitioner_id}
                                onChange={({value}) => {
                                    setFieldValue('practitioner_id', value);
                                }}
                            />
                        </FormField>
                        <FormField label={t('Description')} error={errors.description}>
                            <TextArea
                                name='description'
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormField>
                        <FormStatus {...status} boxProps={{pad: {vertical: 'small'}}}/>
                        {showCreateButton && <RoutedButton
                            path={`/clients/${values.client_id}/${values.data.type}s/create/${values.data.type === 'manipulation' ? values.data.manipulation?.type_id : ''}`}
                            primary
                            color='accent-1'
                            label={t('Create') + ' ' + t(values.data.type)}
                        />}
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button label={t('Close')} onClick={onClose} color="dark-3" />
                            {id && <DeleteWithConfirmationButton onClick={async () => {
                                try {
                                    await deleteMutation({variables: {id}});
                                    onClose();
                                } catch (e) {
                                    console.log('Error', e);
                                }
                            }}/>}
                            <Button label={id ? t('Update') : t('Create')} primary type='submit' />
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Layer>
    );
};