import React, {FC, ReactElement} from 'react';
import {Box, Heading, Paragraph, Text} from "grommet";
import {useTranslation} from "react-i18next";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import moment from "moment";
import {Tile} from "../../components/Tile";
import {RoutedButton} from "../../components/RoutedButton";
import {PractitionerCell} from "./components/PractitionerCell";
import {DeleteWithConfirmationButton} from "../../components/DeleteWithConfirmationButton";
import {PaymentStatus} from "./tabs/PaymentsTab";

const DELETE_MUTATION = gql`
    mutation ($manipulation_id: Int!) {
        delete_client_manipulation_by_pk(id: $manipulation_id) {
            id
        }
    }
`;

const FETCH_QUERY = gql`
    query ($manipulation_id: Int!) {
        manipulation: client_manipulation_by_pk(id: $manipulation_id) {
            id
            name
            description
            created_at
            medications: client_manipulation_medication_packages {
                quantity
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    prescribing_note
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
            manipulation_type {
                id
                name
            }
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
            client_payment {
                id
                price
                status_id
                description
            }
        }
    }
`

export const ViewManipulation: FC = (): ReactElement => {
    const {id, manipulation_id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {data: {manipulation} = {manipulation: {}}, loading, error} = useQuery(FETCH_QUERY, {variables: {manipulation_id}});
    const [deleteManipulation] = useMutation(DELETE_MUTATION);
    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box gap='small'>
            <Tile loading={loading} boxProps={{gap: 'medium'}}>
                <Box>
                    <Heading level={2} size='small' margin={{vertical: 'small'}}>{`${t(manipulation.manipulation_type.name)} ${t('on')} ${moment(manipulation.created_at).format('ll')}`}</Heading>
                    <Paragraph margin={{left: 'small'}}>{manipulation.description || t('No description.')}</Paragraph>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Medications')}</Text>
                    <Box margin={{left: 'small'}}>
                        {manipulation.medications.length > 0 ? manipulation.medications.map(({id, medication_package, quantity}: any, index: number) =>
                            <Text key={index}>
                                {`${quantity} x ${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                            </Text>
                        ) : <Text>N/A</Text>}
                    </Box>
                </Box>
                <Box gap='small'>
                    <Text weight='bold'>{t('Payment')}</Text>
                    {manipulation.client_payment?.id ?
                        <Box direction='row' gap='small' margin={{left: 'small'}} align='center'>
                            <Text>{manipulation.client_payment.price.toFixed(2)} {t('BGN')}</Text>
                            <PaymentStatus id={manipulation.client_payment.status_id}/>
                        </Box>:
                        <Text margin={{left: 'small'}}>{t('No payment required')}</Text>
                    }
                </Box>
                <Box gap='small' direction='row' align='center'>
                    <Text>{t('Carried out by')}</Text><PractitionerCell practitioner={manipulation.practitioner} size='medium'/>
                </Box>
            </Tile>
            <Box direction='row' gap='small'>
                <DeleteWithConfirmationButton onClick={async () => {
                    try {
                        await deleteManipulation({variables: {manipulation_id}});
                        navigate(`/clients/${id}/manipulations`);
                    } catch (e) {
                        console.log('Error', e);
                    }
                }}/>
                <RoutedButton alignSelf='start' path={`/clients/${id}/manipulations`} secondary label={t('Back')} color='dark-3' />
            </Box>
        </Box>

    );
};