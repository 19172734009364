import React, {FC, ReactElement, useState} from 'react';
import {gql} from "@apollo/client";
import {Box, CheckBox, Text} from "grommet";
import {DataColumnConfig, DataTable} from "../../components/DataTable";
import {useTranslation} from "react-i18next";
import {RoutedButton} from "../../components/RoutedButton";

const FETCH_QUERY = gql`
    query ($limit: Int! $offset: Int! $sort: [medication_package_order_by!] $filter: medication_package_bool_exp) {
        data: medication_package(order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            registered_name
            dosage
            dosage_unit {
                id
                unit
            }
            medication {
                id
                international_nonproprietary_name
            }
            quantity_in_stock
            price
            description
            pharmaceutical_form
            needs_restock
        }
        data_aggregate: medication_package_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: string;
    registered_name: string;
    quantity_in_stock: number;
    price: number;
    pharmaceutical_form: string;
    description: string;
    dosage: string;
    dosage_unit_id: number;
    dosage_unit: {
        id: number
        unit: string
    };
    medication: {
        id: number
        international_nonproprietary_name: string
    };
    needs_restock: boolean;
}

const columns: DataColumnConfig<RowType>[] = [
    {
        property: 'registered_name',
        header: 'Name',
        search: true,
        searchExpression: (term => (
            {
                _or: [
                    {medication: {international_nonproprietary_name: {_ilike: `%${term}%`}}},
                    {medication: {international_nonproprietary_name_en: {_ilike: `%${term}%`}}},
                    {registered_name: {_ilike: `%${term}%`}},
                    {license_holder: {_ilike: `%${term}%`}}
                ]
            }
        )),
        render: ({registered_name, medication}) => <Text>{`${registered_name} (${medication.international_nonproprietary_name})`}</Text>
    },
    {
        property: 'pharmaceutical_form',
        header: 'Pharmaceutical Form',
        search: true,
    },
    {
        property: 'dosage',
        header: 'Dosage',
        render: ({dosage, dosage_unit}) => <Text>{`${dosage} ${dosage_unit?.unit}`}</Text>
    },
    {
        property: 'price',
        header: 'Sell Price',
        units: 'BGN',
        render: ({price}) => (price || 0).toFixed(2)
    },
    {
        property: 'quantity_in_stock',
        header: 'Quantity in Stock',
        render: ({quantity_in_stock, needs_restock}) =>
            <Text
                weight={needs_restock ? 'bold' : 'normal'}
                color={needs_restock ? 'status-error' : 'dark-1'}
                size={needs_restock ? 'xlarge' : 'medium'}
            >
                {quantity_in_stock}
            </Text>
    },
];

export const Stock: FC = (): ReactElement => {
    const {t} = useTranslation();
    const [filter, setFilter] = useState<any>({});
    return (
        <DataTable
            query={FETCH_QUERY}
            emptyMessage={t('No medication packages found.')}
            columns={columns}
            sort={{
                direction: 'asc',
                external: true,
                property: 'quantity_in_stock'
            }}
            defaultFilter={{_and: [{medication_package_restocks: {}}, filter]}}
        >
            <Box direction='row' gap='small'>
                <RoutedButton path='/settings/stock/restock' label={t('Restock')}/>
                <CheckBox
                    checked={Object.keys(filter).length > 0}
                    label={t('Needs restock immediately')}
                    onChange={({target}) => setFilter(target.checked ? {needs_restock: {_eq: true}} : {})}
                />
            </Box>
        </DataTable>
    );
};