import React, {FC, ReactElement} from 'react';
import {CreateOptionSelect, CreateOptionSelectProps} from "../../../../components/CreateOptionSelect";
import {gql} from "@apollo/client";

const CREATE_DOSAGE_UNIT_MUTATION = gql`
    mutation ($unit: String!) {
        option: insert_medication_dosage_unit_one(object: {unit: $unit}) {
            id
            unit
        }
    }
`;

const FETCH_DOSAGE_UNIT_QUERY = gql`
    query {
        options: medication_dosage_unit {
            id
            unit
        }
    }
`;

export const DosageUnitSelect: FC<Omit<CreateOptionSelectProps, 'query' | 'mutation' | 'labelKey'>> = (props): ReactElement => {
    return (
        <CreateOptionSelect
            {...props}
            query={FETCH_DOSAGE_UNIT_QUERY}
            mutation={CREATE_DOSAGE_UNIT_MUTATION}
            labelKey='unit'
        />
    );
};