import React, {FC, ReactElement} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Button, FormField, Spinner, TextInput} from "grommet";
import {GeneralErrorField} from "./GeneralErrorField";
import {useAuth} from "../../../services/auth";
import {useTranslation} from "react-i18next";

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(32, 'Maximum 32 characters')
        .required('Required'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required('Password repeat is required'),
});

interface FormFields {
    password: string,
    passwordRepeat: string
}

const initialValues = {
    password: '',
    passwordRepeat: ''
};

export const PasswordResetForm: FC<any> = ({password_reset_token}): ReactElement => {
    const { t } = useTranslation();
    const {resetPassword} = useAuth();
    const {errors, values, handleChange, handleSubmit, status, isSubmitting} = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: FormFields, actions) => {
            await resetPassword({...values, password_reset_token}, actions);
        }
    });

    return (
        <form onSubmit={handleSubmit}>
            <FormField label={t('Password')} error={errors.password}>
                <TextInput
                    name="password"
                    type="password"
                    value={values.password || ""}
                    onChange={handleChange}
                />
            </FormField>
            <FormField label={t('Repeat Password')} error={errors.passwordRepeat}>
                <TextInput
                    name="passwordRepeat"
                    type="password"
                    value={values.passwordRepeat || ""}
                    onChange={handleChange}
                />
            </FormField>
            <GeneralErrorField status={status}/>
            <Button
                type='submit'
                label={t('Change')}
                primary
                fill='horizontal'
                hoverIndicator
                icon={isSubmitting ? <Spinner/> : undefined}
            />
        </form>
    );
};