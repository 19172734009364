import React, {FC, ReactElement} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {Box} from "grommet";
import {RoutedButton} from "../../components/RoutedButton";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import moment from "moment";
import {DataColumnConfig, DataTable} from "../../components/DataTable";
import {useNavigate} from "react-router-dom";

const columns: DataColumnConfig<RowType>[] = [
    {
        property: 'first_name',
        header: 'Name',
        search: true,
        searchExpression: (term => ({
            _or: [
                {first_name: {_ilike: `%${term}%`}},
                {middle_name: {_ilike: `%${term}%`}},
                {last_name: {_ilike: `%${term}%`}}
            ]
        })),
        render: ({first_name, last_name}: any) => `${first_name} ${last_name}`
    },
    {
        property: 'email',
        search: true,
        header: 'Email',
    },
    {
        property: 'role_id',
        header: 'Role',
    },
    {
        property: 'birth_date',
        header: 'Birthday',
        render: (datum: any) =>
            datum.birth_date && moment(datum.birth_date).format('Do MMM'),
    },
];

interface RowType {
    first_name: string;
    middle_name: string;
    last_name: string;
    role_id: string;
    email: string;
    active: boolean;
    birth_date: string;
}

const FETCH_USERS = gql`
    query ($limit: Int! $offset: Int! $sort: [user_order_by!] $filter: user_bool_exp) {
        data: user (order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            first_name
            middle_name
            last_name
            role_id
            email
            active
            birth_date
        }
        data_aggregate: user_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;


export const PractitionersPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <Box>
            <PageHeader name={t('Practitioners')}/>
                <DataTable
                    query={FETCH_USERS}
                    emptyMessage={'No users found.'}
                    columns={columns}
                    onClickRow={(event) => navigate(`${event.datum.id}`)}
                    sort={{
                        direction: 'asc',
                        external: true,
                        property: 'first_name'
                    }}
                />
        </Box>
    );
};