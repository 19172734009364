import React, {FC, ReactElement, useState} from 'react';
import {Accordion, Box, Button, Text} from "grommet";
import {FieldArray, FormikContextType} from "formik";
import {FormFields} from "./ExaminationForm";
import {useTranslation} from "react-i18next";
import {ComponentProps, PrescribeMedicationAccordionPanel} from "./PrescribeMedicationAccordionPanel";
import moment from "moment";

export interface MedicationPackage {
    id?: number
    medication: {
        id: string,
        international_nonproprietary_name: string,
    },
    medication_id: string,
    pharmaceutical_form: string,
    prescribing_note: string,
    registered_name: string,
    dosage: string,
    dosage_unit: {
        id: string,
        unit: string
    },
    dosage_unit_id: string,
    __typename?: string
}

export interface MedicationPackageFields {
    medication_package_id: string,
    medication_package: MedicationPackage
}

export const medicationPackageStub: MedicationPackage = {
    medication: {
        id: '',
        international_nonproprietary_name: '',
    },
    prescribing_note: '',
    medication_id: '',
    pharmaceutical_form: '',
    registered_name: '',
    dosage: '',
    dosage_unit: {
        id: '',
        unit: ''
    },
    dosage_unit_id: ''
}

export const medicationPackageFieldsStub: MedicationPackageFields = {
    medication_package_id: '',
    medication_package: medicationPackageStub
}

export interface FormFieldsWithMedications<T> {
    medications: T[]
}

interface Props<T extends MedicationPackageFields> {
    formik: FormikContextType<FormFieldsWithMedications<T>>,
    component: FC<ComponentProps<T>>,
    medicationStub: T,
    message?: string
}

export const PrescribeMedicationsInput: FC<Props<any>> = ({formik, component, medicationStub, message = 'No medications prescribed.'}): ReactElement=> {
    const {values} = formik;
    const {t} = useTranslation();
    const [activeIndex, setActiveIndex] = useState([0]);

    return (
        <FieldArray name="medications">
            {({remove, push}) => (
                <Box gap='small'>
                    <Accordion activeIndex={activeIndex} onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}>
                        {values.medications.length > 0 ?
                        values.medications.map((medication, index) => (
                            <PrescribeMedicationAccordionPanel
                                key={index}
                                index={index}
                                active={activeIndex.includes(index)}
                                remove={remove}
                                component={component}
                                formik={formik}
                            />
                        )) : <Text weight='bold' margin={{left: 'small', vertical: 'small'}}>{t(message)}</Text>}
                    </Accordion>
                    <Button alignSelf='start' secondary label={t('Add Medication')} onClick={() => push(medicationStub)}/>
                </Box>
            )}
        </FieldArray>);
};