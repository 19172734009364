import React, {FC, ReactElement} from 'react';
import {Box, FormField, TextInput} from "grommet";
import {FormikContextType, getIn} from "formik";
import {useTranslation} from "react-i18next";
import {
    FormFieldsWithMedications,
    MedicationPackageFields, medicationPackageFieldsStub
} from "../../Clients/components/ExaminationForm/PrescribeMedicationsInput";

export interface CocktailMedicationFields extends MedicationPackageFields{
    quantity: number
}


export const medicationStub: CocktailMedicationFields = {
    quantity: 1,
    ...medicationPackageFieldsStub
}

export interface CocktailMedicationFieldsProps {
    formik: FormikContextType<FormFieldsWithMedications<CocktailMedicationFields>>,
    index: number,
    modified: boolean
}

export const CocktailMedicationAdditionalFields: FC<CocktailMedicationFieldsProps> = ({index, modified, formik}): ReactElement => {
    const {values, errors, handleChange, handleBlur} = formik;
    const {t} = useTranslation();
    return (
        <Box>
            <FormField label={t('Quantity')} error={getIn(errors, `medications.${index}.quantity`)}>
                <TextInput
                    name={`medications.${index}.quantity`}
                    value={values.medications[index].quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='number'
                    min={1}
                    max={50}
                    step={1}
                />
            </FormField>
        </Box>
    );
};