import React, {FC, ReactElement, useMemo} from 'react';
import {Box} from "grommet";
import {useTranslation} from "react-i18next";
import {ExaminationForm} from "./components/ExaminationForm/ExaminationForm";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import moment from "moment";

const UPDATE_MUTATION = gql`
    mutation ($examination: client_examination_set_input! $examination_id: Int! $diagnoses: [client_examination_diagnoses_insert_input!]!) {
        update_client_examination_by_pk(pk_columns: {id: $examination_id} _set: $examination) {
            id
            description
            start
            routine
            diagnoses: client_examination_diagnoses {
                diagnosis {
                    id
                    name
                }
            }
        }
        
        delete_client_examination_diagnoses(where: {examination_id: {_eq: $examination_id}}) {
            affected_rows
        }
        insert_client_examination_diagnoses(objects: $diagnoses) {
            affected_rows
        }
    }
`;

const FETCH_QUERY = gql`
    query ($examination_id: Int!) {
        examination: client_examination_by_pk(id: $examination_id) {
            id
            routine
            description
            start_date: start
            diagnoses: client_examination_diagnoses {
                diagnosis {
                    id
                    name
                }
            }
            medications: client_medications {
                prescription_note
                start
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    prescribing_note
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
        }
    }
`

export const EditExamination: FC = (): ReactElement => {
    const {id, examination_id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [updateExamination] = useMutation(UPDATE_MUTATION);
    const {data, loading, error} = useQuery(FETCH_QUERY, {variables: {examination_id}});
    const initialValues = useMemo(() => ({
            ...data?.examination,
            start_time: moment(data?.start_date).format('HH:mm'),
            diagnoses: data?.examination?.diagnoses.map(({diagnosis}: any) => diagnosis.id)
    }), [data]);

    if (loading) {
        return <SpinnerContainer/>
    }

    return (
        <Box>
            <ExaminationForm
                initialValues={initialValues}
                onSubmit={async (data, actions) => {
                try {
                    const {__typename, id: examination_id, client_examination_diagnoses, client_medications, ...examination} = data;
                    const diagnoses = client_examination_diagnoses.data.map((diagnosis: any) => ({...diagnosis, examination_id}))
                    await updateExamination({variables: {examination, examination_id, diagnoses}});
                    navigate(`/clients/${id}/examinations`);
                } catch (e) {
                    actions.setStatus({type: 'error', message: t('Server responded with an error.')})
                }
            }
            }/>
        </Box>
    );
};