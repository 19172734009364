import React, { FC, ReactElement } from 'react';
import * as Yup from "yup";
import {Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Button, CheckBox, DateInput, FormField, MaskedInput, Text, TextArea, TextInput} from "grommet";
import {DiagnosesSelect} from "./DiagnosesSelect";
import {Tile} from "../../../../components/Tile";
import {RoutedButton} from "../../../../components/RoutedButton";
import {
    FormFieldsWithMedications,
    PrescribeMedicationsInput
} from "./PrescribeMedicationsInput";
import {EXAMINATION_PRICE, TIME_MASK} from "../../../../services/constants";
import moment from "moment";
import {FormStatus} from "../../../../components/FormStatus";
import {ClientMedicationFields, MedicationFields, medicationStub} from "./ClientMedicationFields";
import {medicationPackageValidationSchema} from "./MedicationPackagePreview";

const validationSchema = Yup.object().shape({
    routine: Yup.boolean(),
    description: Yup.string(),
    diagnoses: Yup.array().of(Yup.number()),
    require_payment: Yup.boolean(),
    price: Yup.number(),
    medications: Yup.array().of(Yup.object().shape({
        prescription_note: Yup.string(),
        start: Yup.string(),
        medication_package_id: Yup.string(),
        medication_package: medicationPackageValidationSchema
    }))
});


export interface FormFields extends FormFieldsWithMedications<MedicationFields>{
    routine: boolean,
    start_date: string,
    start_time: string,
    duration: number,
    description: string,
    require_payment: boolean,
    price: number,
    diagnoses: number[],
}

const initialValues: FormFields = {
    routine: false,
    start_date: moment().toISOString(),
    start_time: moment().format('HH:mm'),
    duration: 5,
    require_payment: true,
    price: EXAMINATION_PRICE,
    description: '',
    diagnoses: [],
    medications: []
};

interface Props {
    onSubmit: (data: any, actions: FormikHelpers<FormFields>) => void,
    initialValues?: Partial<FormFields>,
    disableMedications?: boolean
}

export const ExaminationForm: FC<Props> = ({onSubmit, initialValues: initial = {}}): ReactElement => {
    const {t} = useTranslation();
    const {id} = useParams();
    const formik = useFormik({
        initialValues: {
            ...initialValues,
            ...initial
        },
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                const {start_date, start_time, diagnoses, medications, require_payment, price, ...rest} = values;
                const time = moment(start_time)
                const start = moment(start_date).set({hours: time.get('hours'), minutes: time.get('minutes')}).toISOString();
                let payment = {};
                if (require_payment) {
                    payment = {
                        client_payment: {
                            data: {
                                price,
                                client_id: id,
                            }
                        }
                    }
                }
                const data = {
                    ...rest,
                    client_id: id,
                    start,
                    client_examination_diagnoses: {
                        data: diagnoses.map((diagnosis) => ({diagnosis_id: diagnosis}))
                    },
                    client_medications: {
                        data: medications.map(({medication_package_id, medication_package, ...rest}) => {
                            const data = {
                                ...rest,
                                client_id: id,
                            };
                            if (medication_package_id) {
                                return {...data, medication_package_id};
                            }
                            const {medication, dosage_unit, ...medication_package_data} = medication_package;
                            return {
                                ...data,
                                medication_package: {
                                    data: medication_package_data
                                }
                            }
                        })
                    },
                    ...payment
                };
                await onSubmit(data, actions);
            } catch (e) {
                actions.setStatus({type: 'error', message: t('Something went wrong.')})
            }
        }
    });
    const {values, errors, handleChange, handleBlur, setFieldValue, touched, isSubmitting} = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <Tile loading={isSubmitting}>
                    <Box direction='row' gap='medium'>
                        <FormField label={t('Date')} error={errors.start_date} width='small'>
                            <DateInput
                                name='start_date'
                                format="dd/mm/yyyy"
                                value={values.start_date}
                                onChange={({value}) => setFieldValue('start_date', value)}
                            />
                        </FormField>
                        <FormField label={t('Time')} error={errors.start_time} width='xsmall'>
                            <MaskedInput
                                name='start_time'
                                mask={TIME_MASK}
                                value={values.start_time}
                                onChange={event => {
                                    setFieldValue('start_time', event.target.value)
                                }}
                            />
                        </FormField>
                        <FormField label={t('Duration')} error={errors.duration} contentProps={{direction: 'row'}} width='xsmall'>
                            <TextInput
                                name='duration'
                                value={values.duration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type='number'
                                min={5}
                                max={60}
                                step={5}
                            />
                            <Text alignSelf='center'>{t('min')}</Text>
                        </FormField>
                    </Box>
                    <FormField label={t('Diagnoses')} error={errors.diagnoses}>
                        <DiagnosesSelect
                            name='diagnoses'
                            value={values.diagnoses}
                            onChange={({value: nextValue}) => setFieldValue('diagnoses', nextValue)}
                        />
                    </FormField>
                    <FormField label={t('Prescribe Medications')} contentProps={{pad: {bottom: 'medium'}}}>
                        <PrescribeMedicationsInput
                            // @ts-ignore
                            formik={formik}
                            component={ClientMedicationFields}
                            medicationStub={medicationStub}
                        />
                    </FormField>
                    <FormField label={t('Description')} error={errors.description}>
                        <TextArea
                            name='description'
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={5}
                        />
                    </FormField>
                    <FormStatus {...formik.status} boxProps={{pad: {vertical: 'small'}}} />
                    <Box direction='row-responsive' gap='medium'>
                        <CheckBox
                        name='require_payment'
                        checked={values.require_payment}
                        label={t('Requires Payment?')}
                        onChange={(event) => setFieldValue('require_payment', event.target.checked)}
                        />
                        {values.require_payment && <Box direction='row' gap='small'><FormField label={t('Price')} error={errors.price}>
                            <TextInput
                                name='price'
                                value={values.price}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                        </FormField>
                            <Text alignSelf='center'>{t('Normal Price')}: <strong>{EXAMINATION_PRICE}</strong></Text>
                        </Box>}
                    </Box>
                </Tile>
                <Box direction='row' gap='small' margin={{top: 'small'}}>
                    <Button primary label={t('Save')} type='submit' onClick={() =>
                        // Remove medication_package_id when medication fields have been touched (i.e create new medication)
                        touched?.medications?.forEach((medication, index) => {
                            medication.medication_package && setFieldValue(`medications.${index}.medication_package_id`, '')
                        })
                    }/>
                    <RoutedButton path={`/clients/${id}/examinations`} secondary label={t('Cancel')} color='dark-3' />
                </Box>
            </Form>
        </FormikProvider>
    );
};