import React, { FC, ReactElement } from 'react';
import {CLIENT_TYPE} from "../../../services/constants";
import {Box, Text} from "grommet";
import {useTranslation} from "react-i18next";

interface Props {
    id?: number
}

export const ClientTypeTag: FC<Props> = ({id}): ReactElement | null => {
    const {t} = useTranslation();
    switch (id) {
        case CLIENT_TYPE.GP:
            return <Box background='accent-4' round='medium' pad='xsmall' alignSelf='start'><Text>{t('GP')}</Text></Box>
        case CLIENT_TYPE.VIP:
            return <Box background='status-critical' round='medium' pad='xsmall' alignSelf='start'><Text weight='bold'>{t('VIP')}</Text></Box>
        case CLIENT_TYPE.RANDOM:
            return <Box background='status-disabled' round='medium' pad='xsmall' alignSelf='start'><Text>{t('Random')}</Text></Box>
        default:
            return null
    }

};