import React, { FC, ReactElement } from 'react';
import {Box, Text} from "grommet";

interface Props {
    label: string,
    color: string
}

export const Tag: FC<Props> = ({label, color}): ReactElement => {
    return (
        <Box alignSelf='start' round='medium' pad='xsmall' background={color}>
            <Text>{label}</Text>
        </Box>
    );
};