import React, {FC, ReactElement} from 'react';
import {Box} from "grommet";
import {useTranslation} from "react-i18next";
import {ExaminationForm} from "./components/ExaminationForm/ExaminationForm";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {FETCH_CLIENT} from "./components/ClientInformationTile";
import {SpinnerContainer} from "../../components/SpinnerContainer";
import {EXAMINATION_PRICE} from "../../services/constants";

const CREATE_MUTATION = gql`
    mutation ($data: client_examination_insert_input!) {
        examination: insert_client_examination_one(object: $data) {
            id
            description
            start
            diagnoses: client_examination_diagnoses {
                diagnosis {
                    id
                    name
                }
            }
            medications: client_medications {
                id
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
            physician_id
            physician {
                id
                first_name
                last_name
            }
            client_payment {
                id
                price
                status_id
            }
        }
    }
`;

const FETCH_DISCOUNT = gql`
    query ($id: uuid!) {
        client: client_by_pk(id: $id) {
            id
            discount_rate
        }
    }
`;

export const CreateExamination: FC = (): ReactElement => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [createExamination] = useMutation(CREATE_MUTATION);
    const {data, loading} = useQuery(FETCH_DISCOUNT, {variables: {id}});

    if (loading) {
        return <SpinnerContainer/>;
    }
    return (
        <Box>
            <ExaminationForm initialValues={{price: (1 - data.client.discount_rate) * EXAMINATION_PRICE}} onSubmit={async (data, actions) => {
                try {
                    const result = await createExamination({variables: {data}, refetchQueries: [FETCH_CLIENT]});
                    navigate(`/clients/${id}/examinations/${result.data.examination.id}`);
                } catch (e) {
                    actions.setStatus({type: 'error', message: t('Server responded with an error.')})
                }
            }
            }/>
        </Box>
    );
};