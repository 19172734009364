import React, { FC, ReactElement } from 'react';
import {Box} from "grommet";
import {PageHeader} from "../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {ProfileForm} from "./ProfileForm";
import {CheckHistoryHours} from "./CheckHistoryHours";
import {gql, useQuery} from "@apollo/client";

const FETCH_USER_ID = gql`
    query {
        userId @client
    }
`;

export const ProfilePage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {data} = useQuery(FETCH_USER_ID);
    return (
        <Box gap='small'>
            <PageHeader name={t('Profile')}/>
            <CheckHistoryHours id={data.userId}/>
            <ProfileForm/>
        </Box>
    );
};