import React, {FC, ReactElement} from 'react';
import {Box, Button, Menu} from "grommet";
import {Notification} from "grommet-icons";
import {useAuth} from "../../../services/auth";
import {gql, useQuery} from "@apollo/client";
import {SpinnerContainer} from "../../../components/SpinnerContainer";
import {useNavigate} from "react-router-dom";
import {PractitionerCell} from "../../../pages/Clients/components/PractitionerCell";
import {CheckInOutStatus} from "./CheckInOutStatus";
import {useTranslation} from "react-i18next";

const FETCH_USER_DATA = gql`
    query ($user_id: uuid!) {
        userId @client @export(as: "user_id")
        user: user_by_pk(id: $user_id) {
            id
            email
            first_name
            last_name
        }
    }
`;

export const UserSession: FC = (): ReactElement => {
    const {logout} = useAuth();
    const {t} = useTranslation();
    const {data, loading} = useQuery(FETCH_USER_DATA);
    const navigate = useNavigate();
    if (loading) {
        return <SpinnerContainer align='end' size='small' pad={{right: 'medium'}}/>
    }
    return (
        <Box direction='row' gap='medium' align='center'>
            <CheckInOutStatus/>
            {/*<Button badge icon={<Notification/>}/>*/}
            <Menu
                label={<PractitionerCell
                    practitioner={data?.user}
                    size='large'
                    boxProps={{gap: 'small'}}
                    textProps={{color: 'dark-1', size: 'small', weight: 'bold'}}
                />}
                items={[
                    { label: t('Profile'), onClick: () => navigate('/profile') },
                    { label: t('Logout'), onClick: logout },
                ]}
            />
        </Box>
    );
};