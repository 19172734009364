import React, { FC, ReactElement } from 'react';
import {Box, BoxProps, Text} from 'grommet';

interface Props {
    type?: 'ok' | 'error' | 'warning' | 'unknown' | 'critical',
    message?: string,
    boxProps?: BoxProps
}

// TODO: Make it more clever
export const FormStatus: FC<Props> = ({message, type = 'unknown', boxProps}): ReactElement | null => {
    if (message) {
        return (
            <Box {...boxProps}>
                <Text color={`status-${type}`} weight='bold'>{message}</Text>
            </Box>
        );
    }
    return null;
};