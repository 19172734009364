import React, { FC, ReactElement } from 'react';
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {Box, Text} from "grommet";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {PasswordResetForm} from "./components/PasswordResetForm";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {}

export const PasswordResetPage: FC<Props> = (): ReactElement => {
    const {token} = useParams();
    const { t } = useTranslation();
    return (
        <AuthPageWrapper>
            <Box pad='medium' gap='small'>
                <Text alignSelf='center'>{t('change_password')}</Text>
                <PasswordResetForm password_reset_token={token}/>
                <RoutedAnchor label={t('Sign in')} path='/sign-in' size='xsmall' alignSelf='center'/>
            </Box>
        </AuthPageWrapper>
    );
};