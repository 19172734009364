import React, { FC, ReactElement } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box} from "grommet";
import {ManipulationForm} from "./components/ManipulationForm";
import {gql, useMutation} from "@apollo/client";
import {MANIPULATION_TYPE_NAME} from "../../services/constants";
import {FETCH_CLIENT} from "./components/ClientInformationTile";

const CREATE_MUTATION = gql`
    mutation ($data: client_manipulation_insert_input!) {
        manipulation: insert_client_manipulation_one(object: $data) {
            id
            name
            type_id
            created_at
            manipulation_type {
                id
                name
            }
            description
            practitioner_id
            practitioner {
                id
                first_name
                last_name
            }
            client_payment {
                id
                price
                status_id
            }
        }
    }
`;

export const CreateManipulation: FC = (): ReactElement => {
    const {id, type_id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [createManipulation] = useMutation(CREATE_MUTATION);
    return (
        <Box>
            {/* @ts-ignore */}
            <ManipulationForm initialValues={{type_id: parseInt(type_id || '1'), name: t(MANIPULATION_TYPE_NAME[parseInt(type_id || '1')])}} onSubmit={async (data, actions) => {
                try {
                    const result = await createManipulation({variables: {data}, refetchQueries: [FETCH_CLIENT]});
                    navigate(`/clients/${id}/manipulations/${result.data.manipulation.id}`);
                } catch (e) {
                    actions.setStatus({type: 'error', message: t('Server responded with an error.')})
                }
            }
            }/>
        </Box>
    );
};