import {Box, Button, Heading, Layer, Text, TextInput} from 'grommet';
import React, {FC, ReactElement, useState} from 'react';
import {useTranslation} from "react-i18next";
import {gql, useMutation} from "@apollo/client";

interface Props {
    onClose: () => void,
    client: any,
    refetchQuery: any
}

const UPDATE_DISCOUNT_RATE = gql`
    mutation($id: uuid! $discount: float8!) {
        update_client_by_pk(pk_columns: {id: $id} _set: {discount_rate: $discount}) {
            id
            discount_rate
        }
    }
`


export const SetDiscountModal: FC<Props> = ({onClose, client, refetchQuery}): ReactElement => {
    const {t} = useTranslation();
    const [discountRate, setDiscountRate] = useState<any>(0);
    const [updateDiscountRate] = useMutation(UPDATE_DISCOUNT_RATE, {refetchQueries: [refetchQuery]})
    return (
        <Layer
            position='center'
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <Box pad="medium" gap="small" width="medium">
                <Heading level={3} margin="none">
                    {`${t('Set discount rate for')} ${client.first_name} ${client.last_name}`}
                </Heading>
                <TextInput icon={<Text weight='bold'>%</Text>} value={discountRate} onChange={(event) => setDiscountRate(event.target.value)} />
                <Box
                    as="footer"
                    gap="small"
                    direction="row"
                    align="center"
                    justify="end"
                    pad={{top: 'medium', bottom: 'small'}}
                >
                    <Button label={t('Cancel')} color="dark-4" onClick={onClose}/>
                    <Button
                        label={<Text weight='bold' color="white">{t('Save')}</Text>}
                        onClick={async () => {
                            await updateDiscountRate({variables: {discount: discountRate / 100, id: client.id}});
                            onClose();
                        }}
                        primary
                        color="status-ok"
                    />
                </Box>
            </Box>
        </Layer>
    );
};