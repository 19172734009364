import React, {FC, ReactElement} from 'react';
import {Box, ColumnConfig, Text} from "grommet";
import {gql} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {PractitionerCell} from "../components/PractitionerCell";
import {DataTable} from "../../../components/DataTable";
import {useTranslation} from "react-i18next";


const FETCH_EXAMINATIONS = gql`
    query ($limit: Int! $offset: Int! $sort: [client_examination_order_by!] $filter: client_examination_bool_exp) {
        data: client_examination(order_by: $sort where: $filter offset: $offset limit: $limit) {
            id
            description
            start
            diagnoses: client_examination_diagnoses {
                diagnosis {
                    id
                    name
                }
            }
            medications: client_medications {
                id
                medication_package_id
                medication_package {
                    id
                    medication_id
                    medication {
                        id
                        international_nonproprietary_name
                    }
                    pharmaceutical_form
                    registered_name
                    dosage
                    dosage_unit_id
                    dosage_unit {
                        id
                        unit
                    }
                }
            }
            physician_id
            physician {
                id
                first_name
                last_name
            }
        }
        data_aggregate: client_examination_aggregate (where: $filter) {
            aggregate {
                count
            }
        }
    }
`;

interface RowType {
    id: number;
    description?: string;
    start: string;
    medications: {
        id: number,
        medication_package: {
            medication: {
                id: number,
                international_nonproprietary_name: string
            },
            registered_name: string,
            dosage: string,
            dosage_unit: {
                id: number,
                unit: string
            }
        }
    }[],
    diagnoses: {
        diagnosis: {
            id: number,
            name: string
        }
    }[],
    physician?: {
        id: string
        first_name: string
        last_name: string
    }
}

const columns: ColumnConfig<RowType>[] = [
    {
        property: 'start',
        header: 'Date',
        render: ({start}) => moment(start).format('ll')
    },
    {
        property: 'diagnoses',
        header: 'Diagnoses',
        render: ({diagnoses}) => <Box>
            {diagnoses.length > 0 ? diagnoses.map(({diagnosis}) => <Text key={diagnosis.id}
                                                                         size='small'>{diagnosis.name}</Text>) :
                <Text>N/A</Text>}
        </Box>
    },
    {
        property: 'description',
        header: 'Description',
        render: ({description}) => <Text truncate size='small'>{description}</Text>
    },
    {
        property: 'medications',
        header: 'Medications',
        render: ({medications}) => <Box>
            {medications.length > 0 ? medications.map(({id, medication_package}) =>
                <Text key={id} size='small'>
                    {`${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                </Text>
            ) : <Text>N/A</Text>}
        </Box>
    },
    {
        property: 'practitioner.id',
        header: 'Physician',
        render: ({physician}) => <PractitionerCell practitioner={physician}/>
    },
]


export const ExaminationsTab: FC = (): ReactElement => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    return (
        <DataTable
            query={FETCH_EXAMINATIONS}
            emptyMessage={t('No examinations found.')}
            columns={columns}
            onClickRow={(event) => navigate(`${event.datum.id}`)}
            sort={{
                direction: 'desc',
                external: true,
                property: 'start'
            }}
            defaultFilter={{client_id: {_eq: id}}}
        />
    );
};