import React, {FC, ReactElement, useState} from 'react';
import {SelectExtendedProps} from "grommet";
import {DocumentNode, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {FormSelect} from "./FormSelect";

export interface SearchSelectProps extends Omit<SelectExtendedProps, 'options'> {
    query: DocumentNode,
    variables?: any,
    limit?: number
}

export const SearchSelect: FC<SearchSelectProps> = ({query, variables, limit = 20, ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [offset, setOffset] = useState(0);
    const [options, setOptions] = useState([]);
    useQuery(query, {
        variables: {...variables, query: `%${searchQuery}%`, limit, offset},
        onCompleted: data =>
            setOptions(options => {
                const ids = options.map((option: any) => option.id);
                return options.concat(data.options.filter((option: any) => !ids.includes(option.id)))
            })

    });
    return (
        <FormSelect
            valueKey={{key: 'id', reduce: true}}
            options={options}
            emptySearchMessage={t('No results found.')}
            // onSelect={(alo) => console.log('ass', alo)}
            onSearch={text => {
                setOptions((options: any) =>
                    Array.isArray(rest.value) ?
                        // @ts-ignore
                        options.filter((option: any) => rest?.value?.includes(option.id)) :
                        []
                );
                setSearchQuery(text);
                setOffset(0);
            }}
            onMore={() => setOffset(offset => offset + limit)}
            {...rest}
        />
    );
};

SearchSelect.displayName = 'Select';