import React, {FC, ReactElement} from 'react';
import {Box, BoxProps} from "grommet";
import {SpinnerContainer} from "./SpinnerContainer";

export interface TileProps {
    loading?: boolean,
    boxProps?: BoxProps,
}

export const Tile: FC<TileProps> = ({children, loading = false, boxProps = {}}): ReactElement => {
    return (
        <Box background='white' border={{color: 'light-3'}} pad='small' round='xsmall' {...boxProps}>
            {loading ? <SpinnerContainer/> : children}
        </Box>
    );
};