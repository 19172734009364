import {HttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {AUTH_TOKEN_KEY, loadState} from "../../localStorage";

const link = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = loadState(AUTH_TOKEN_KEY);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        }
    }
});

export const httpLink = authLink.concat(link);
