import React, {FC, ReactElement} from 'react';
import {SelectExtendedProps} from "grommet";
import {DocumentNode, useQuery} from "@apollo/client";
import {FormSelect} from "./FormSelect";

export interface EnumSelectProps extends Omit<SelectExtendedProps, 'options'> {
    query: DocumentNode,
    variables?: any,
    options?: SelectExtendedProps['options']
}

export const EnumSelect: FC<EnumSelectProps> = ({query, variables, ...rest}): ReactElement => {
    const {data, loading, error} = useQuery(query, {variables});
    return (
        <FormSelect
            labelKey='name'
            valueKey={{key: 'id', reduce: true}}
            options={data?.options || []}
            {...rest}
        />
    );
};

EnumSelect.displayName = 'Select';