import React, { FC, ReactElement } from 'react';
import {Box, BoxProps, Heading, Text} from "grommet";
import {RoutedAnchor} from "./RoutedAnchor";
import {useTranslation} from "react-i18next";

interface Props {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
    boxProps?: BoxProps
}

export const FatalError: FC<Props> = ({boxProps = {}}): ReactElement => {
    const {t} = useTranslation();
    return (
        <Box pad='medium' align='center' justify='center' {...boxProps}>
            <Heading level={1} size='xlarge' margin={{vertical: 'small'}}>OOPS!</Heading>
            <Text size='xlarge'>{t('Something went wrong.')}</Text>
            <RoutedAnchor path='/' method='replace' label={t('Back')} margin={{top: 'medium'}} onClick={() => window.location.reload()}/>
        </Box>
    );
};