import React, { FC, ReactElement } from 'react';
import {CreateOptionSelect, CreateOptionSelectProps} from "../../../../components/CreateOptionSelect";
import {gql} from "@apollo/client";

const CREATE_MEDICATION_MUTATION = gql`
    mutation ($international_nonproprietary_name: String!) {
        option: insert_medication_one(object: {international_nonproprietary_name: $international_nonproprietary_name}) {
            id
            international_nonproprietary_name
        }
    }
`;

const FETCH_MEDICATIONS_QUERY = gql`
    query($query: String!, $limit: Int!, $offset: Int!) {
        options: medication(
            where: {
                _or: [
                    { international_nonproprietary_name: { _ilike: $query } }
                    { international_nonproprietary_name_en: { _ilike: $query } }
                ]
            }
            limit: $limit
            offset: $offset

        ) {
            id
            international_nonproprietary_name
        }
    }
`

export const MedicationSelect: FC<Omit<CreateOptionSelectProps, 'query' | 'mutation' | 'labelKey'>> = (props): ReactElement => {
    return (
        <CreateOptionSelect
            query={FETCH_MEDICATIONS_QUERY}
            mutation={CREATE_MEDICATION_MUTATION}
            labelKey='international_nonproprietary_name'
            fetchAllAtOnce={false}
            {...props}
        />
    );
};