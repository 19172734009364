import React, {FC, ReactElement} from 'react';
import {Box, Tip, TipProps} from "grommet";

export const getOpacity = (minutes: number) =>
    minutes > 450 ? 'strong' : minutes > 360 ? 'medium' : 'weak';

interface Props {
    minutes: number,
    tip: TipProps['content']
}

export const Dot: FC<Props> = ({minutes, tip}): ReactElement => {
    return (
        <Tip content={tip}>
            <Box width='20px' height='20px' background={{color: 'status-ok', opacity: getOpacity(minutes)}}/>
        </Tip>
    );
};