import React, {FC, ReactElement, useContext} from 'react';
import {Tile} from "../../../components/Tile";
import {useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {Anchor, Box, ResponsiveContext, Stack, Text} from "grommet";
import {Avatar} from "../../../components/Avatar";
import {useTranslation} from "react-i18next";
import {MailOption, Money, Phone, UserPolice} from "grommet-icons";
import moment from "moment";
import {GENDER} from "../../../services/constants";
import {ClientTypeTag} from "./ClientTypeTag";

const IconBox: FC = ({children}): ReactElement => (
    <Box direction='row' gap='small'>
        {children}
    </Box>
)

export const getAge = (date: string) => Math.floor(moment.duration(moment().diff(date)).asYears())

export const FETCH_CLIENT =  gql`
    query ($id: uuid!) {
        client: client_by_pk(id: $id) {
            id
            first_name
            middle_name
            last_name
            national_identification_number
            primary_physician_id
            primary_physician {
                id
                first_name
                last_name
            }
            user_referral_id
            birth_date
            phone_number
            email
            city_id
            city {
                id
                name
                country_id
                country {
                    id
                    name
                }
            }
            gender
            type_id
        }
        pending_payments: client_payment_aggregate(where: {_and: [
            {client_id: {_eq: $id}},
            {status_id: {_eq: 1}}
        ]}) {
            aggregate {
                count
            }
        }
    }
`;

interface Props {
}

export const ClientInformationTile: FC<Props> = (): ReactElement => {
    const {t} = useTranslation();
    const {id} = useParams();
    const size = useContext(ResponsiveContext);
    const {data: {client, pending_payments} = {client: {}, pending_payments: {}}, loading} = useQuery(FETCH_CLIENT, {variables: {id}});

    return (
        <Tile loading={loading} boxProps={{gap: 'medium', direction: 'row', wrap: true, justify: 'between', background: 'none', border: false, flex: false}}>
            <Box direction='row' gap='small' align='center'>
                <Stack anchor='top-right'>
                    <Avatar firstName={client?.first_name} lastName={client?.last_name} size='xxlarge'/>
                    <ClientTypeTag id={client?.type_id}/>
                </Stack>
                <Box>
                    <Text weight='bold'>{`${client.first_name} ${client.middle_name || ''} ${client.last_name || ''}`}</Text>
                    <Text>{t('NIN')}: {client.national_identification_number}</Text>
                    {pending_payments?.aggregate?.count > 0 && <Box direction='row' gap='small' margin={{top: 'small'}}>
                        <Money color='status-warning' size='32px'/>
                        <Text weight='bold' size='large'>{t('Pending Payments')}</Text>
                    </Box>}
                </Box>
            </Box>
            <Box direction='row' gap='small' align='center' margin={{top: size === 'small' ? 'medium' : undefined}}>
                <Box><Text weight='bold'>{t('Birth date')}</Text><Text>{client.birth_date ? moment(client.birth_date).format('D MMM') : 'N/A'}</Text></Box>
                <Box><Text weight='bold'>{t('Age')}</Text><Text>{client.birth_date ? getAge(client.birth_date) : 'N/A'}</Text></Box>
                <Box><Text weight='bold'>{t('Gender')}</Text><Text>{client.gender === null ? 'N/A' : client.gender === GENDER.MALE ? t('Male') : t('Female')}</Text></Box>
                <Box><Text weight='bold'>{t('City')}</Text><Text>{client.city?.name || 'N/A'}</Text></Box>
            </Box>
            <Box gap='small' margin={{top: size === 'small' ? 'medium' : undefined}}>
                <IconBox>
                    <UserPolice color='plain'/>
                    <Text weight='bold'>{client.primary_physician_id ? `${client.primary_physician.first_name} ${client.primary_physician.last_name}` : 'N/A'}</Text>
                </IconBox>
                <IconBox>
                    <Phone color='plain'/>
                    <Anchor label={client.phone_number || 'N/A'} href={client.phone_number ? `tel:${client.phone_number}` : undefined}/>
                </IconBox>
                <IconBox>
                    <MailOption color='plain'/>
                    <Anchor label={client.email || 'N/A'} href={client.email ? `mailto:${client.email}` : undefined}/>
                </IconBox>
            </Box>
        </Tile>
    );
};