import React, {FC, ReactElement} from 'react';
import {Anchor, Box} from "grommet";
import {useTranslation} from "react-i18next";

interface Props {
}

export const URL = process.env.REACT_APP_LANDING_PAGE_URL;


export const AuthPageWrapper: FC<Props> = ({children}): ReactElement => {
    const { t } = useTranslation();
    return (
        <Box gap='medium'><Box elevation='xsmall' background='white' round='xsmall' flex={false} width='medium'>
            {children}
        </Box><Anchor alignSelf='center' href={URL}>{t('Back to')} start-clinic.com</Anchor></Box>
    );
};
