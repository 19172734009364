import React, {FC, ReactElement, useMemo} from 'react';
import {PageHeader} from "../../components/PageHeader";
import {Box, Menu, Tab, Tabs} from "grommet";
import {useTranslation} from "react-i18next";
import {FormNext} from "grommet-icons";
import {ClientInformationTile} from "./components/ClientInformationTile";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useHasAccess} from "../../services/auth";
import {USER_ROLE} from "../../services/constants";

const ActionMenu: FC = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const manipulationAccess = useHasAccess([USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN, USER_ROLE.NURSE]);
    const examinationAccess = useHasAccess([USER_ROLE.ADMINISTRATOR, USER_ROLE.PHYSICIAN]);

    if (!manipulationAccess) {
        return null;
    }

    return (
        <Menu
            label={t('Add')}
            icon={<FormNext color='brand'/>}
            items={[
                { label: t('Examination'), onClick: () => navigate(`/clients/${id}/examinations/create`) },
                { label: t('Manipulation'), onClick: () => navigate(`/clients/${id}/manipulations/create`) },
            ].filter((item, index) => index !== 0 || examinationAccess)}
        />
    )
}

const TABS = ['examinations', 'manipulations', 'notes', 'information', 'payments'];

export const ClientPage: FC = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = useMemo(() => {
        const loc = location.pathname.split('/');
        return TABS.findIndex((tab) => loc.includes(tab));
    }, [location]);

    return (
        <Box overflow='auto'>
            <PageHeader name={t('Client')} action={<ActionMenu/>}/>
            <Box gap='medium' flex={false}>
                <ClientInformationTile/>
                <Tabs flex={false} alignControls='start' onActive={(index) => navigate(TABS[index])} activeIndex={activeTab}>
                    <Tab title={t('Examinations')}/>
                    <Tab title={t('Manipulations')}/>
                    {/*<Tab title={t('Medications')}/>*/}
                    <Tab title={t('Notes')}/>
                    <Tab title={t('Information')}/>
                    <Tab title={t('Payments')}/>
                </Tabs>
                <Outlet/>
            </Box>
        </Box>
    );
};