import React, {FC, ReactElement, useMemo} from 'react';
import {AccordionPanel, Box, FormField, Text} from "grommet";
import {getOptionLabel, MedicationPackageSelect} from "./MedicationPackageSelect";
import {FormikContextType, getIn} from "formik";
import {useTranslation} from "react-i18next";
import {MedicationPackagePreview} from "./MedicationPackagePreview";
import {FormClose, FormDown, FormUp} from "grommet-icons";
import {FormFieldsWithMedications, MedicationPackageFields} from "./PrescribeMedicationsInput";
import {MedicationFields} from "./ClientMedicationFields";
import {CocktailMedicationFields} from "../../../Settings/components/CocktailMedicationAdditionalFields";

const renderPanelHeader = (title: string, active: boolean, remove: () => void): ReactElement => (
    <Box direction="row" align="center" pad="medium" justify='between'>
        <Box direction='row' gap='small'>
            <Text weight='bold'>{title}</Text>
            {active ? <FormUp color='brand'/> : <FormDown color='brand'/>}
        </Box>
        <FormClose onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            remove();
        }}/>
    </Box>
);

export interface ComponentProps<T> {
    index: number,
    modified: boolean,
    formik: FormikContextType<FormFieldsWithMedications<T>>
}

interface Props<T extends MedicationPackageFields> {
    index: number,
    active?: boolean,
    remove: (index: number) => void,
    formik: FormikContextType<FormFieldsWithMedications<T>>,
    component: FC<ComponentProps<T>>
}

export const PrescribeMedicationAccordionPanel: FC<Props<MedicationFields | CocktailMedicationFields>> = ({index, active = false, remove, formik, component: Component}): ReactElement => {
    const {setFieldValue, values, errors, handleChange, handleBlur, touched} = formik;
    const {t} = useTranslation();
    const value = useMemo(() => values.medications[index], [values, index]);
    const modified = useMemo(() => touched.medications && touched.medications[index], [touched, index]);
    const title = useMemo(() =>
        value?.medication_package_id ?
            getOptionLabel(value.medication_package) :
            t('Medication') + ' ' + (index + 1),
        [value, index, t]);

    return (
        <AccordionPanel header={renderPanelHeader(title, active, () => remove(index))}>
            <Box background='light-3' pad='small'>
                {modified ? <Text margin={{left: 'small', vertical: 'small'}} size='large'>{t('New Medication')}: {getOptionLabel(value.medication_package)}</Text> :
                    <FormField label={t('Medication')} error={getIn(errors, `medications.${index}.medication_package.registered_name`)}>
                    <MedicationPackageSelect
                        name={`medications.${index}.medication_id`}
                        value={values.medications[index]?.medication_package_id || ''}
                        onChange={({value, option}) => {
                            setFieldValue(`medications.${index}.medication_package_id`, value);
                            setFieldValue(`medications.${index}.medication_package`, option);
                        }}
                    />
                </FormField>}
                {value?.medication_package_id && <Box>
                    <MedicationPackagePreview
                        medicationPackage={value.medication_package}
                        index={index}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                    />
                    <Component
                        index={index}
                        modified={!!modified}
                        formik={formik}
                    />
                </Box>}
            </Box>
        </AccordionPanel>
    );
};