import React, {FC, ReactElement, useContext} from 'react';
import {Box, BoxProps, ResponsiveContext, Text, TextProps} from "grommet";
import {Avatar} from "../../../components/Avatar";

interface Props {
    size?: TextProps['size'],
    practitioner?: {
        id: string,
        first_name: string,
        last_name: string
    },
    boxProps?: BoxProps,
    textProps?: TextProps
}

export const PractitionerCell: FC<Props> = ({practitioner, size = 'small', textProps, boxProps}): ReactElement => {
    const windowSize = useContext(ResponsiveContext);
    if (practitioner) {
        return (
            <Box direction='row' gap='xsmall' align='center' {...boxProps}>
                <Avatar firstName={practitioner?.first_name} lastName={practitioner?.last_name} size={size}/>
                {windowSize !== 'small' && <Text {...textProps}>{`${practitioner.first_name} ${practitioner.last_name}`}</Text>}
            </Box>
        )
    }
    return <Text {...textProps}>N/A</Text>
};