import moment from "moment";

export const capitalize = (s: string): string => (s && s[0].toUpperCase() + s.slice(1)) || ''

// improving Search support of special characters
export const getRegExpSearch = (text: any) => {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    return new RegExp(escapedText, 'i');
};

export const convertNullsToEmptyStrings = (values: { [key: string]: string | number | boolean | null } = {}) => {
    const emptyStrings: { [key: string]: string | number | boolean | null } = {};
    Object.keys(values).forEach(key => {
        emptyStrings[key] = values[key] === null || values[key] === undefined ? '' : values[key]
    })
    return emptyStrings;
}

export const nullifyEmptyValues = (values: { [key: string]: string | number | boolean | null }) => {
    const nullified: { [key: string]: string | number | boolean | null } = {};
    Object.keys(values).forEach(key => {
        nullified[key] = values[key] === '' ? null : values[key]
    })
    return nullified;
}
// @ts-ignore
export const removeEmptyValues = (values: any) => Object.entries(values).reduce((a, [k, v]) => (v === '' || v === null ? a : (a[k] = v, a)), {})


export const getDate = (timestamp?: string): string => moment(timestamp).toISOString().split('T')[0]
