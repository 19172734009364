import React, {FC, ReactElement, useMemo, useState} from 'react';
import {Tile} from "./Tile";
import {
    Box,
    Pagination,
    Spinner,
    Text,
    DataTable as GrommetDataTable,
    ColumnConfig,
    DataTableExtendedProps
} from "grommet";
import {useTranslation} from "react-i18next";
import {DocumentNode, useQuery} from "@apollo/client";

const LoadingPlaceholder: FC = (): ReactElement => (
    <Box
        fill
        align="center"
        justify="center"
        direction="row"
        pad="large"
        gap="small"
        background={{ color: 'background-front', opacity: 'strong' }}
    >
        <Spinner />
        <Text weight="bold">Loading ...</Text>
    </Box>
)

export interface DataColumnConfig<T> extends ColumnConfig<T> {
    searchExpression?: (term: string) => any
}

interface Props<T> extends DataTableExtendedProps<T>{
    query: DocumentNode,
    columns: DataColumnConfig<T>[],
    emptyMessage?: string,
    defaultFilter?: object
}

/**
 * TODO: Generate query dynamically by passing a param with table_name and a string with all the needed columns and relations to fetch
 */
export const DataTable: FC<Props<any>> = ({children, query, columns, sort: defaultSort, emptyMessage = 'No records found.', defaultFilter, ...rest}): ReactElement => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // TODO: Add component to select page size and store it in local storage
    const [filter, setFilter] = useState<any>({});
    const [sort, setSort] = useState<{[key: string]: string} | null>(defaultSort ? {[defaultSort.property]: defaultSort.direction} : null);
    // Used to speed up column searchExpression select in onSearch
    const columnIndexMap = useMemo<{[property: string]: number}>(() => columns.reduce((acc, column, index) => (
        {...acc, [column.property]: index}
    ), {}), [columns]);

    const {t} = useTranslation();
    const {data, loading, error} = useQuery(query, {
        variables: {
            limit: pageSize,
            offset: (page - 1) * pageSize,
            sort,
            filter: defaultFilter ? {_and: [defaultFilter, filter]} : filter
        },
        fetchPolicy: 'no-cache'
    });

    const hasMultiplePages = useMemo(() => data?.data_aggregate?.aggregate.count > pageSize, [data, pageSize]);

    return (
        <Box gap='small' fill>
            <Tile boxProps={{overflow: 'auto'}}>
                <GrommetDataTable
                    pin
                    // Translate column headers
                    columns={columns.map(column => ({...column, header: typeof column.header === 'string' ? t(column.header) : column.header}))}
                    data={data?.data || []}
                    primaryKey='id'
                    placeholder={loading ? <LoadingPlaceholder/> : undefined}
                    sort={defaultSort}
                    sortable
                    onSort={({property, direction}) =>
                        setSort({[property]: direction})
                    }
                    // @ts-ignore
                    onSearch={(searches: {[property_name: string]: string}) => {
                        const filters = Object.keys(searches).map((prop: string) => {
                            if (columns[columnIndexMap[prop]].searchExpression) {
                                // @ts-ignore
                                return columns[columnIndexMap[prop]].searchExpression(searches[prop])
                            }
                            return {[prop]: {_ilike: `%${searches[prop]}%`}};
                        });
                        setFilter({_and: filters});
                    }}
                    step={pageSize}
                    {...rest}
                />
                {data?.data?.length === 0 && <Text margin={{left: 'small', top: 'small'}}>{t(emptyMessage)}</Text>}
            </Tile>
            <Box direction={hasMultiplePages ? 'row-reverse' : 'row'} justify='between' flex={false} wrap>
                {hasMultiplePages && <Pagination
                    numberItems={data?.data_aggregate?.aggregate.count}
                    page={page}
                    step={pageSize}
                    onChange={({page}) => setPage(page)}
                />}
                {children}
            </Box>
        </Box>
    );
};