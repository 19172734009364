import React, {FC, ReactElement, SyntheticEvent} from 'react';
import {Anchor, AnchorProps} from "grommet";
import { useNavigate } from "react-router-dom";


interface Props extends AnchorProps {
    path: string,
    method?: 'push' | 'replace',
    onClick?: (event: SyntheticEvent) => void,
}

export const RoutedAnchor: FC<Props> = ({path, method = 'push', onClick = () => {}, ...rest}): ReactElement => {
    const navigate = useNavigate();
    return (
        <Anchor
            {...rest}
            href={path}
            onClick={(event, ...args) => {
                const modifierKey = event.ctrlKey || event.metaKey;
                // if the user right-clicked in the Anchor we should let it go
                if (modifierKey) {
                    return;
                }
                event.preventDefault();
                navigate(path, {replace: method === 'replace'});
                onClick(event, ...args);
            }}
        />
    );
};
