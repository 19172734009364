export const AUTH_TOKEN_KEY = 'authToken';
export const REFRESH_TOKEN = 'refreshToken';

export const loadState = (key: string = 'state') : any => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (e) {
        return undefined;
    }
};

export const saveState = (key : string = 'state', value : any) : void => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (e) {
        // Ignore write errors.
    }
};

export const removeState = (key : string = 'state') : void => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        // Ignore remove errors.
    }
};
