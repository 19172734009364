import React, {FC, ReactElement} from 'react';
import {Box} from "grommet";
import {PageHeader} from "../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {gql, useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {ClientForm} from "./components/ClientForm/ClientForm";

export interface FormFields {
    first_name: string,
    middle_name: string,
    last_name: string,
    national_identification_number: string,
    primary_physician_id: string,
    user_referral_id: string,
    birth_date: string,
    phone_number: string,
    email: string,
    city_id: string,
    country_id: string | number,
    address_line: string,
    postcode: string,
    gender: boolean | null,
    type_id: string
}

const CREATE_CLIENT_MUTATION = gql`
    mutation ($client: client_insert_input!) {
        client: insert_client_one(object: $client) {
            id
            first_name
            middle_name
            last_name
            national_identification_number
            primary_physician_id
            user_referral_id
            birth_date
            phone_number
            email
            city_id
            address_line
            postcode
            gender
            type_id
        }    
    }
`

interface Props {
}

export const CreateClientPage: FC<Props> = (): ReactElement => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [createClient] = useMutation(CREATE_CLIENT_MUTATION);

    return (
        <Box overflow='auto'>
            <PageHeader name={t('Create Client')}/>
            <ClientForm onSubmit={async (client, actions) => {
                try {
                    // @ts-ignore
                    const {data} = await createClient({variables: {client}});
                    navigate(`/clients/${data.client.id}`);
                } catch (e) {
                    actions.setStatus({type: 'error', message: t('Something went wrong.')})
                }
            }}/>
        </Box>
    );
};