import React, {FC, ReactElement, forwardRef} from 'react';
import {Box, Heading, Paragraph, Text} from "grommet";
import {Logo} from "../../../components/Logo";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getAge} from "./ClientInformationTile";

interface Props {
    data?: {
        start_date: string,
        physician: {
            id: string,
            first_name: string,
            last_name: string
        },
        medications: {
            id: number,
            medication_package: {
                registered_name: string,
                medication: {
                    international_nonproprietary_name: string
                },
                dosage: string,
                dosage_unit: {
                    unit: string
                }
            },
            prescription_note?: string,
            start?: string
        }[],
        client: {
            id: string,
            first_name: string,
            middle_name: string,
            last_name: string,
            birth_date: string,
            city: {
                id: number,
                name: string
            },
            address_line: string
        }
    }
}

export const Prescription: FC<Props> = forwardRef<HTMLDivElement, Props>(({data}, ref): ReactElement => {
    const {t} = useTranslation();
    return (
        <Box ref={ref} pad='medium'>
            <Box direction='row' justify='between' align='center'>
                <Box direction='row' gap='small' align='center'>
                    <Logo/>
                    <Text weight='bold' color='dark-1' size='large'>Start Clinic</Text>
                </Box>
                <Text size='large'>{t('Date')} <strong>{moment(data?.start_date).format('L')}</strong></Text>
            </Box>
            <Box>
                <Heading size='small' alignSelf='center'>{t('Prescription')}</Heading>
                <Box direction='row' gap='medium'>
                    <Box basis='1/2'>
                        <Text>{t('From')} {t('Physician')}
                            <strong>{`${data?.physician.first_name} ${data?.physician.last_name}`}</strong> ({t('Physician ID')}
                            <strong>{`${data?.physician.id}`}</strong>) {t('from')} Start Clinic, {t('clinic_address')}
                        </Text>
                    </Box>
                    <Box basis='1/2'>
                        <Text>{t('For Patient')} <strong>{`${data?.client.first_name} ${data?.client.middle_name} ${data?.client.last_name}`}</strong> ({data?.client.birth_date ? getAge(data.client.birth_date) + ' ' + t('years') : ''})</Text>
                    </Box>
                </Box>
                <Heading level={3}>{t('Medications')}</Heading>
                <Box>
                    {data?.medications.map(({id, medication_package, prescription_note, start}: any, index) =>
                    <Box key={id} gap='xsmall'>
                        <Text>
                            {index + 1}. {`${medication_package.registered_name} (${medication_package.medication.international_nonproprietary_name}) ${medication_package.dosage} ${medication_package.dosage_unit.unit}`}
                        </Text>
                        <Text margin={{left: 'small'}}>{t('Start on')} <strong>{moment(start).format('ll')}</strong></Text>
                        <Heading level={4} margin={{left: 'small', vertical: 'none'}} >{t('Note')}</Heading>
                        <Paragraph margin={{left: 'small'}}>{prescription_note}</Paragraph>
                    </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});