import React, { FC, ReactElement } from 'react';
import {capitalize} from "../../../services/helpers";
import {SelectExtendedProps} from "grommet";
import {useTranslation} from "react-i18next";
import {FormSelect} from "../../../components/FormSelect";

const GENDER_OPTIONS = [
    {id: 0, name: 'Male'},
    {id: 1, name: 'Female'},
]

export const GenderSelect: FC<Omit<SelectExtendedProps, 'options'>> = (props): ReactElement => {
    const {t} = useTranslation();
    return (
        <FormSelect
            options={GENDER_OPTIONS}
            valueKey={{key: 'id', reduce: true}}
            labelKey={(option) => t(capitalize(option.name))}
            {...props}
        />
    );
};