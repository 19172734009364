import React, {FC, ReactElement} from 'react';
import {Box, Text} from "grommet";
import {SignInForm} from "./components/SignInForm";
import {RoutedAnchor} from "../../components/RoutedAnchor";
import {AuthPageWrapper} from "./components/AuthPageWrapper";
import {SocialAccountsSignIn} from "./components/SocialAccountsSignIn";
import {useTranslation} from "react-i18next";

export const SignInPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <AuthPageWrapper>
            <Box pad='medium' gap='small'>
                <Text alignSelf='center'>{t('Login with your existing account')}</Text>
                <SignInForm/>
                <RoutedAnchor label={t('Forgotten Password?')} path='/request-password-reset' size='xsmall' alignSelf='center'/>
                {/*<Text alignSelf='center'>Or with your social account</Text>*/}
                {/*<SocialAccountsSignIn/>*/}
            </Box>
        </AuthPageWrapper>
    );
};