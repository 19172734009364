import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import browserHistory from "../browserHistory";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    integrations: [
        new Integrations.BrowserTracing({
            // @ts-ignore
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory)
        })
    ],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});