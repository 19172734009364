import React, {FC, ReactElement, useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import {FormSelect} from "../../../components/FormSelect";
import {SelectExtendedProps} from "grommet";
import {getRegExpSearch} from "../../../services/helpers";

const FETCH_COUNTRIES = gql`
    query {
        options: country {
            id
            nicename
        }
    }
`;

// SearchSelect is not used here because we want to load all countries at once
export const CountrySelect: FC<Omit<SelectExtendedProps, 'options'>> = (props): ReactElement => {
    const [options, setOptions] = useState([]);
    const {data: {options: defaultOptions} = {options: []}, loading} = useQuery(FETCH_COUNTRIES, {onCompleted: data => setOptions(data.options)});
    return (
        <FormSelect
            {...props}
            valueKey={{key: 'id', reduce: true}}
            labelKey='nicename'
            options={options}
            onClose={() => setOptions(defaultOptions)}
            onSearch={text => {
                const exp = getRegExpSearch(text);
                setOptions(defaultOptions.filter(({nicename}: {id: number, nicename: string}) => exp.test(nicename)));
            }}
        />
    );
};