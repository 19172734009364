import React, {FC, ReactElement} from 'react';
import {SearchSelect, SearchSelectProps} from "../../../../components/SearchSelect";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";

const SEARCH_DIAGNOSES = gql`
    query ($query: String! $limit: Int! $offset: Int!) {
        options: diagnosis(where: {_or: [{name: {_ilike: $query}}, {code: {_ilike: $query}}]} limit: $limit offset: $offset) {
            id
            code
            name
        }
    }
`;

// TODO: Bug! On search you have to double click on the option to select it
export const DiagnosesSelect: FC<Omit<SearchSelectProps, 'query'>> = ({ ...rest}): ReactElement => {
    const {t} = useTranslation();
    return (
        <SearchSelect
            query={SEARCH_DIAGNOSES}
            labelKey='name'
            messages={{multiple: t('Multiple Selected')}}
            closeOnChange={false}
            multiple
            {...rest}
        />
    );
};