import React, {FC, ReactElement} from 'react';
import {Select, SelectExtendedProps} from "grommet";
import {FormFieldTheme} from "./FormFieldTheme";
import {useTranslation} from "react-i18next";

export const FormSelect: FC<SelectExtendedProps> = (props): ReactElement => {
    const {t} = useTranslation();
    return (
        <FormFieldTheme>
            <Select {...props} messages={{multiple: t('Multiple')}}/>
        </FormFieldTheme>
    );
};

FormSelect.displayName = 'Select';