import React, {FC, ReactElement, useState} from 'react';
import {Box, Button, ButtonExtendedProps, Heading, Layer, Text} from "grommet";
import {useTranslation} from "react-i18next";

interface Props extends ButtonExtendedProps {
    onClick: () => void
}

export const DeleteWithConfirmationButton: FC<Props> = ({onClick, ...rest}): ReactElement => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <Box>
            {open && (
                <Layer
                    position="center"
                    onClickOutside={() => setOpen(false)}
                    onEsc={() => setOpen(false)}
                >
                    <Box pad="medium" gap="small" width="medium">
                        <Heading level={3} margin="none">
                            {t('Confirm')}
                        </Heading>
                        <Text>{t('Are you sure you want to delete?')}</Text>
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: 'medium', bottom: 'small' }}
                        >
                            <Button label={t('Cancel')} onClick={async (e) => setOpen(false)} color="dark-3" />
                            <Button
                                label={
                                    <Text color="white">
                                        <strong>{t('Delete')}</strong>
                                    </Text>
                                }
                                onClick={async () => {
                                    setOpen(false);
                                    await onClick();
                                }}
                                primary
                                color="status-critical"
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
            {/* @ts-ignore */}
           <Button primary label={t('Delete')} color='status-error' onClick={() => setOpen(true)} {...rest}/>
        </Box>
    );
};